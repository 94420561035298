export const UPDATE_ITEMS_LIST = "UPDATE_ITEMS_LIST";
export const UPDATE_ITEM = "UPDATE_ITEM";
export const SET_UPLOAD_STATUS = "SET_UPLOAD_STATUS";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const ADD_ITEM = "ADD_ITEM";
export const ADD_TO_QUEUE = "ADD_TO_QUEUE";
export const REMOVE_FROM_QUEUE = "REMOVE_FROM_QUEUE";
export const STOP_QUEUE_CHECK = "STOP_QUEUE_CHECK";
export const CLEAR_QUEUE = "CLEAR_QUEUE";
export const UPDATE_QUEUE = "UPDATE_QUEUE";
export const CLEAR_ITEMS = "CLEAR_ITEMS";
export const UPDATE_DETAILS = "UPDATE_DETAILS";
export const SET_CURRENT_CONTENT = "SET_CURRENT_CONTENT";
export const UPDATE_SELECTED_DATA = "UPDATE_SELECTED_DATA";
export const UPDATE_SHOWED_COMPONENT = "UPDATE_SHOWED_COMPONENT";
export const UPDATE_STRUCTURE = "UPDATE_STRUCTURE";
export const ADD_ATTRIBUTE_VALUE = "ADD_ATTRIBUTE_VALUE";
export const UPDATE_ATTRIBUTES = "UPDATE_ATTRIBUTES";
export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const REMOVE_CONTENT = "REMOVE_CONTENT";
export const REMOVE_DETAILS = "REMOVE_DETAILS";
export const ADD_CONTENT = "ADD_CONTENT";
export const UPDATE_CONTENTS = "UPDATE_CONTENTS";
export const REMOVE_PROP = "REMOVE_PROP";


