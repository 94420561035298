import {
    UPDATE_FILTERS, UPDATE_USER_DATA,
} from "./actionTypes";

export function updateFilters(filters) {
    return {
        type: UPDATE_FILTERS,
        payload: filters
    };
}

export function updateUserData(data) {
    return {
        type: UPDATE_USER_DATA,
        payload: data
    };
}
