import React, {Component} from 'react';
import {connect} from "react-redux";
import ApiData from "./ApiData";
import {addAttributeValue, updateShowedComponent} from "../actions/ItemDetailsAction";
import store from "../store";
import {EDIT_SKU} from "../constants";

class AddAttributeValue extends Component {
    constructor(props) {
        super(props);
        this.apiData = new ApiData()

    }

    componentDidMount() {
        this.setState({
            notValid: false,
            attributeValyue: null,
            selected: this.props.selected,
            structure: this.props.structure,
        })
    }

    onChangeAttributeValue = (e) => {
        this.setState({
            'attributeValue': e.target.value
        })
    };

    addAttributeValue = async () => {
        const attributeId = this.props.attributeId;
        const {attributeValue} = this.state
        const {success, data, errors} = await this.apiData.createAttributeValue({
            attribute_id: attributeId,
            'UserCatalogItemAttributeValues[name]': attributeValue
        });
        if (success) {
            store.dispatch(addAttributeValue(attributeId, attributeValue))
            this.handleClose()
        } else {
            if (errors['usercatalogitemattributeValues-name'] !== undefined) {
                this.setState({
                    error_message: errors['usercatalogitemattributes-name'][0],
                    not_valid: true
                })
            }
        }
    }


    handleClose() {
        store.dispatch(updateShowedComponent(EDIT_SKU))
    }

    render = () => {
        return (
            this.state &&
            <>
                <div
                    className={this.state.not_valid ? 'form-group not_valid' : "form-group"}>
                    <input className="form-control" required type="text"
                           value={this.state.attributeValyue}
                           name="attributeValue"
                           onChange={this.onChangeAttributeValue}
                           placeholder="Attribute Value"
                           maxLength={255}/>
                    <span className="error-message">{this.state.error_message}</span>
                </div>
                <div className="col-sd-6 update-submit">
                    <button
                        className="btn btn_white btn_border btn_small btn_shadow_none"
                        onClick={() => {
                            this.addAttributeValue()
                        }}>Submit
                    </button>
                </div>
                <div className="col-sd-6 update-submit">
                    <button
                        className="btn btn_white btn_border btn_small btn_shadow_none"
                        onClick={() => {
                            this.handleClose()
                        }}>Close
                    </button>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    const {itemDetails} = state
    return {
        structure: itemDetails ? itemDetails.structure : {},
        selected: itemDetails ? itemDetails.details.attributes : {},
        attributeId: itemDetails ? itemDetails.props.attributeId : null,
        attributeValue: itemDetails ? itemDetails.props.attributeValue : null
    }
}

export default connect(mapStateToProps)(AddAttributeValue);