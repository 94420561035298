import React, {Component} from 'react';
import {Modal, ModalBody} from 'reactstrap';
import {NotificationManager} from 'react-notifications';
import {connectModal, hide, show} from "redux-modal";
import apiData from "./ApiData";
import store from "../store";
import {removeItem, updateItemsList, updateThumbnail} from "../actions/itemActions";
import {removeContent} from "../actions/ItemDetailsAction";
import {connect} from "react-redux";

class ConfirmRemoveModal extends Component {
    constructor(props, context) {
        super(props, context);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.state = {
            show: true,
            name: null
        };
        this.apiData = new apiData()
    }

    handleClose() {
        this.setState({show: false, editAttributes: false, showSort: false, showAttributes: true});
        this.props.handleHide();
    }

    handleShow() {

        this.setState({show: true});
    }

    componentDidMount() {
        this.setState({
            itemDetails: store.getState().itemDetails.details
        }, () => {
            this.handleShow()
        })

    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {itemDetails} = nextProps
        if (itemDetails.contents.length) {
            store.dispatch(updateThumbnail(itemDetails.contents[0].thumbnail, itemDetails.sku_id));
        } else {
            store.dispatch(removeItem(itemDetails.sku_id))
            this.props.hide('ItemModal')
        }
        this.setState({
            itemDetails: nextProps.itemDetails
        })

    }

    render() {
        return (
            <Modal isOpen={this.state.show} toggle={this.handleClose} className="item-modal">
                <ModalBody>
                    <div className="popup popup-images prewiew">
                        <div className="sweet-alert showSweetAlert visible" data-custom-class=""
                             data-has-cancel-button="false" data-has-confirm-button="false"
                             data-allow-outside-click="true" data-has-done-function="true" data-animation="pop"
                             data-timer="null">
                            <h2></h2>
                            <button className="popup__close" onClick={this.handleClose}></button>

                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                                    <path fill="#A29EA1" fillRule="nonzero"
                                          d="M25 0c13.807 0 25 11.193 25 25S38.807 50 25 50 0 38.807 0 25 11.193 0 25 0zm0 2C12.297 2 2 12.297 2 25s10.297 23 23 23 23-10.297 23-23S37.703 2 25 2zm-.024 34c.578 0 1.06.195 1.446.585.385.39.578.862.578 1.415a1.94 1.94 0 0 1-.578 1.415c-.386.39-.868.585-1.446.585a1.893 1.893 0 0 1-1.398-.585A1.94 1.94 0 0 1 23 38c0-.553.193-1.024.578-1.415.386-.39.852-.585 1.398-.585zM26.5 11l-.361 20H23.86L23.5 11h3z"></path>
                                </svg>
                                <h3 className="title">Confirm action</h3><span>Are you sure you want to delete this
                                Item?</span>
                                <div className="popup-subscription__btn">
                                    <button id="swal-confirm-button" className="btn btn_bg-red" onClick={async () => {

                                        const {type, id} = this.props
                                        if (type === "remove_content") {
                                            const {itemDetails} = this.state;

                                            const {result,message} = await this.apiData.deleteContent({'id': id})
                                            if (result) {
                                                store.dispatch(removeContent(id))
                                                this.handleClose();
                                                NotificationManager.success(message);

                                            }

                                        }
                                        if (type === "remove_sku") {
                                            const {success,message} = await this.apiData.deleteItem(id)
                                            if (success) {
                                                store.dispatch(removeItem(id));
                                                this.handleClose();
                                                this.props.hide('ItemModal')
                                                NotificationManager.success(message);
                                            }
                                        }
                                    }}>Yes, confirm</button>
                                </div>
                                <a className="popup-subscription__link" href="#" onClick={this.handleClose}
                                >No, cancel</a></span>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    const {itemDetails} = state
    return {
        itemDetails: itemDetails ? itemDetails.details : {},
    }
}

export default connectModal({name: 'ConfirmRemoveModal', destroyOnHide: true})(connect(mapStateToProps, {
    show,
    hide
})(ConfirmRemoveModal))