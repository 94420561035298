export function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    var bb = new Blob([ab], {type: mimeString});
    return bb;
}
export function arr_diff ( otherArray) {
    return function(current){
        return otherArray.filter(function(other){
            return other.id === current.id
        }).length === 0;
    }
}
export function copyToClipboard (text, elementId) {
    const textField = document.createElement('textarea');
    textField.innerText = text;
    const parentElement = document.getElementById(elementId);
    parentElement.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    parentElement.removeChild(textField);
}
export function groupBy (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x.value);
        return rv;

    }, {});
};

export  function halfSplice(array) {
    let mid = Math.ceil(array.length/2);
    return [
        array.slice(0, mid),
        array.slice(mid)
    ]
}

export function generateUrlData(filtersArr) {
    let filters = {}
    if (filtersArr != undefined) {
        Object.entries(filtersArr).map((v) => {
            let value = v[1].value;
            if(value!== undefined && typeof value !=='string' && value.length) {
                value = value.join(',')
            }
            filters['UserCatalogItemSearch[attr][' + v[0] + ']'] = value
        })

    }
    return filters
}

export function getFiltersFromUrl(params) {
    let filters={},reqParams={}
    Object.entries(params).map((v) => {
        if (v[0].length && v[1] && v[1].length) {
            let name
            if (v[0].indexOf('[') != -1) {
                name = v[0].split('[');
                name = name[name.length - 1].slice(0, -1)
            } else {
                name = v[0]
            }
            filters[name] = {value: v[1] ? v[1].split(',') : ""}
            reqParams[v[0]] = v[1] ? v[1] : ''
        } else if(v[0] === 'f') {
            filters[v[0]] = ''
            reqParams[v[0]] =  ''
        }
    })
    return {reqParams:reqParams,filters:filters}
}