import {REMOVE_FROM_QUEUE, ADD_TO_QUEUE, STOP_QUEUE_CHECK, CLEAR_QUEUE, UPDATE_QUEUE} from "../actions/actionTypes";

const defaultState = null;

export default (state = defaultState, action = {}) => {
    switch (action.type) {
        case ADD_TO_QUEUE: {
            let items = [];
            if (state) {
                items = state.items
            }
            items.push({id: action.payload.item, status: action.payload.status})
            return {
                items: items,
                stopChecking: action.payload.stopChecking,
                sku_id: state ? state.sku_id : null
            };
        }
        case REMOVE_FROM_QUEUE: {
            return {
                ...state,
                items: state.items.filter(it => it.id !== action.payload.id),
                stopChecking: state.items.length ? false : true,
                sku_id: state ? state.sku_id : null
            }
        }
        case STOP_QUEUE_CHECK: {
            return {
                ...state,
                items: state ? state.items : [],
                stopChecking: true,
                sku_id: state ? state.sku_id : null
            }
        }
        case CLEAR_QUEUE: {
            return {
                items: [],
                stopChecking: false,
                sku_id: action.payload.sku_id
            }
        }
        case UPDATE_QUEUE: {
            return {
                ...state,
                items: state.items.map((item, i) => item.id === action.payload.itemId ? {
                    ...item,
                    status: action.payload.status
                } : item)
            }
        }
        default:
            return state;
    }

};