import {
    UPDATE_DETAILS,
    SET_CURRENT_CONTENT,
    UPDATE_SELECTED_DATA,
    UPDATE_SHOWED_COMPONENT,
    UPDATE_STRUCTURE,
    ADD_ATTRIBUTE_VALUE,
    UPDATE_ATTRIBUTES,
    REMOVE_CONTENT,
    REMOVE_DETAILS,
    ADD_CONTENT,
    UPDATE_CONTENTS, REMOVE_PROP
} from "../actions/actionTypes";
import {EDIT_SKU, SHOW_ATTRIBUTES} from "../constants";

const defaultState = null;
export default (state = defaultState, action = {}) => {
    switch (action.type) {
        case UPDATE_DETAILS: {
            return {
                details: action.payload.details,
                showedComponent: SHOW_ATTRIBUTES
            }
        }
        case SET_CURRENT_CONTENT: {
            return {
                ...state,
                props: {
                    ...state.props,
                    currentContent: action.payload.contentId
                },
            }
        }
        case UPDATE_SELECTED_DATA: {
            return {
                ...state,
                details: action.payload.data,
                showedComponent: SHOW_ATTRIBUTES
            }
        }
        case UPDATE_SHOWED_COMPONENT: {
            return {
                ...state,
                showedComponent: action.payload.component,
                props: {...state.props, ...action.payload.props},
            }
        }

        case UPDATE_STRUCTURE: {
            return {
                ...state,
                structure: action.payload.structure,
                showedComponent: EDIT_SKU
            }
        }
        case UPDATE_ATTRIBUTES: {
            return {
                ...state,
                details: {
                    ...state.details,
                    attributes: {...action.payload.attributes}
                },
            }
        }
        case ADD_ATTRIBUTE_VALUE: {
            const {attributeId, data} = action.payload
            let attributes = Object.assign({}, state.details.attributes)
            let structure = Object.assign({}, state.structure)

            if (!attributes[attributeId]) {
                attributes[attributeId] = {is_visible: true, values: []}
            }
            if (!structure[attributeId]) {
                structure[attributeId] = {is_visible: true, values: []}
            }
            structure[attributeId].values.push({
                'name': data['attribute_value_name'] ? data['attribute_value_name'] : null,
                'value': data['attribute_value_id'] ? data['attribute_value_id'] : null,
            })
            attributes[attributeId].values.push({
                'name': data['attribute_name'] ? data['attribute_name'] : null,
                'value': data['attribute_value_name'] ? data['attribute_value_name'] : null,
                'value_id': data['attribute_value_id'] ? data['attribute_value_id'] : null,
            });
            return {
                ...state,
                structure: structure,
                details: {
                    ...state.details,
                    attributes: attributes
                },
            }


        }
        case REMOVE_CONTENT: {
            return {
                ...state,
                details: {
                    ...state.details,
                    contents: state.details.contents.filter(it => it.id !== action.payload),
                }
            }
        }
        case REMOVE_DETAILS: {
            return {
                ...state,
                details: {
                    sku: null,
                    price: null,
                    description: null,
                    attributes: {},
                    contents: state.details.contents
                }
            }
        }
        case ADD_CONTENT: {
            return {
                ...state,
                details: {
                    ...state.details,
                    contents: [action.payload,...state.details.contents]
                }
            }
        }
        case UPDATE_CONTENTS : {
            return {
                ...state,
                details: {
                    ...state.details,
                    contents: [...action.payload]
                }
            }
        }
        case REMOVE_PROP : {
            let props = state.props;
            delete props[action.payload.name]
            return {
                ...state,
                props: props
            }
        }
        default:
            return state;
    }
};