import React, {Component} from 'react';
import arrayMove from 'array-move';
import ImageContainer from './ImageContainer'
import ApiData from "./ApiData";
import store from "../store";
import {updateThumbnail} from "../actions/itemActions";
import {connect} from "react-redux";
import {show} from "redux-modal";
import {updateContents, updateContentsOrder} from "../actions/ItemDetailsAction";

class ItemSortable extends Component {
    constructor(props, context) {
        super(props, context);
        this.apiData = new ApiData();
    }

    componentDidMount() {
        this.setState({
            images: this.props.images
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            images: nextProps.images,
            uploadQueue: nextProps.uploadQueue,
        })
    }

    onSortEnd = async ({oldIndex, newIndex}) => {
        if (oldIndex === newIndex) {
            return
        }
        var newContents = arrayMove(this.state.images, oldIndex, newIndex)
        var content_list = {};
        for (let [order, content] of Object.entries(newContents)) {
            content.sort_index = content_list[content.id]
            content_list[content.id] = order
        }
        const {success} = await this.apiData.updateOrder({"content_list": content_list});
        if (success === true) {
            store.dispatch(updateThumbnail(newContents[0].thumbnail, this.props.sku_id));
            store.dispatch(updateContents(newContents))
        }
    };

    render() {
        return (
            this.state &&
            <ImageContainer images={this.state.images} onSortEnd={this.onSortEnd} uploadQueue={this.state.uploadQueue} axis="xy"/>
        );
    }
}

function mapStateToProps(state) {
    const {itemDetails, uploadQueue} = state
    return {
        images: itemDetails ? itemDetails.details.contents : {},
        sku_id: itemDetails ? itemDetails.details.sku_id : null,
        uploadQueue: uploadQueue
    }
}

export default connect(mapStateToProps, {show})(ItemSortable);