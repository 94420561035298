import {UPDATE_ITEMS_LIST, UPDATE_ITEM, REMOVE_ITEM, ADD_ITEM, CLEAR_ITEMS} from "../actions/actionTypes";

const defaultState = null;

export default (state = defaultState, action = {}) => {
    switch (action.type) {
        case UPDATE_ITEMS_LIST: {
            if (state && !action.payload.clearOld) {
                return [...state, ...action.payload.itemData.items]
            } else {
                return action.payload.itemData.items
            }
        }
        case REMOVE_ITEM: {
            return state.filter(it => it.id !== action.payload.itemId)
        }
        case ADD_ITEM: {
            return [action.payload.item, ...state]
        }
        case UPDATE_ITEM: {
            return state.map((item, i) => item.id === action.payload.sku_id ? {
                ...item,
                imagePreview: action.payload.image
            } : item)
        }
        case CLEAR_ITEMS: {
            return []
        }
        default:
            return state;
    }
};