export const SHOW_ATTRIBUTES = "showAttributes";
export const EDIT_SKU = "editSku";
export const ADD_SKU = "addSku";
export const SHOW_SORT = "showSort";
export const ADD_ATTRIBUTE = "addAttribute";
export const ADD_ATTRIBUTE_VALUE = "addAttributeValue";
export const SHOW_ASSIGN = "showAssign";
export const SHOW_SHARE = "showShare";
export const SHOW_CONTACTS = "showContacts";
export const colourStyles = {
    control: styles => ({
        ...styles,
        border: '0 !important',
        // This line disable the blue border
        boxShadow: '0 !important',
        '&:hover': {
            border: '0 !important'
        }
    }),
    menu: styles => ({...styles, borderRadius: 'none'}),
    option: (styles, state) => ({
        ...styles,
        transition: 'all .3s ease',
        padding: '10px',
        color: state.isFocused ? '#fff' : '#0f0f0f',
        backgroundColor: state.isFocused ? '#00cac4' : '',
        fontSize: '12px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
    }),
    container: (styles, state) => ({
        ...styles,
        width: "100%",
        border: state.isFocused || state.isActive ? '1px solid #00cac4' : '1px solid #a29ea1'
    }),
};