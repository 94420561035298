import React, {Component} from 'react';
import {Modal, ModalBody} from 'reactstrap';
import {NotificationManager} from 'react-notifications';
import {connectModal} from "redux-modal";
import apiData from "./ApiData";
import {copyToClipboard} from "../helpers";

class ShareGalleryModal extends Component {
    constructor(props, context) {
        super(props, context);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.changeLinkName = this.changeLinkName.bind(this);
        this.state = {
            name: null
        };
        this.apiData = new apiData()
    }


    componentDidMount() {
        var metal_id = this.props.metal_id;
        var sku_id = this.props.sku_id;
        var jewelry_id = this.props.jewelry_id;
        var stone_id = this.props.stone_id;
        var url = process.env.REACT_APP_API_URL + "/gallery/create-shared-link";
        var filterData = {}
        if (sku_id) {
            var sku = this.props.attributes.sku.data.find(o => o.value === sku_id);
            filterData['sku'] = sku;
        }
        if (metal_id) {
            var metal = this.props.attributes.metal.data ? this.props.attributes.metal.data.filter(
                function (e) {
                    return this.indexOf(e.value) !== -1;
                }, metal_id) : []
            if (metal.length) {
                filterData['metal'] = {attribute_id: metal[0].attribute_id, value: metal.map(it => it.value).join(',')};
            }
        }
        if (stone_id) {
            var stone = this.props.attributes.stone_type.data ? this.props.attributes.stone_type.data.filter(
                function (e) {
                    return this.indexOf(e.value) !== -1;
                }, stone_id) : []
            if (stone.length) {
                filterData['stone'] = {attribute_id: stone[0].attribute_id, value: stone.map(it => it.value).join(',')};
            }
        }
        if (jewelry_id) {
            var jewelry = this.props.attributes.jewelry_type.data ? this.props.attributes.jewelry_type.data.filter(
                function (e) {
                    return this.indexOf(e.value) !== -1;
                }, jewelry_id) : []
            if (jewelry.length) {
                filterData['jewelry'] = {
                    attribute_id: jewelry[0].attribute_id,
                    value: jewelry.map(it => it.value).join(',')
                };
            }
        }
        this.handleShow(url, filterData)
    }

    handleClose() {
        this.props.handleHide();
    }

    handleShow(url, filterData) {
        this.setState({filterData: filterData, url: url, showCreatePassword: true});
    }

    createPassword = () => {
        this.setState({
            password: Math.random().toString(36).slice(-8),
            showCreatePassword: false
        })
    }
    createLink = () => {
        var url = this.state.url
        var params = {name: this.state.name, password: this.state.password}
        if (this.state.filterData.sku) {
            params["UserCatalogItemSearch[sku]"] = this.state.filterData.sku.label
        }

        if (this.state.filterData.metal) {
            params["UserCatalogItemSearch[attr][" + this.state.filterData.metal.attribute_id + "]"] = this.state.filterData.metal.value
        }

        if (this.state.filterData.jewelry) {
            params["UserCatalogItemSearch[attr][" + this.state.filterData.jewelry.attribute_id + "]"] = this.state.filterData.jewelry.value
        }

        if (this.state.filterData.stone) {
            params["UserCatalogItemSearch[attr][" + this.state.filterData.stone.attribute_id + "]"] = this.state.filterData.stone.value
        }
        this.apiData.shareItems(params, url, (response) => {
            const {message, type, url} = response
            this.setState({show: false})
            if (type === "success") {
                copyToClipboard(url, 'url')
                NotificationManager.success(message);
            } else {
                NotificationManager.error(message);
            }
        })
    }
    changeLinkName = (e) => {
        this.setState({"name": e.target.value})
    }

    render() {
        return (
            <Modal isOpen={true} toggle={this.handleClose} className="item-modal">
                <ModalBody>
                    <button className="popup__close" onClick={this.handleClose}></button>
                    <div className="popup popup-images prewiew">
                        <div className="popup__wrap">
                            <h4 className="prewiew__name">Create Shared Gallery Link</h4>

                            <div className="row popup__data">
                                <div className="item-details col-sm-12">
                                    <div className="popup__item form__group">
                                        <input className="form__input" placeholder="Link name" name="linkName"
                                               type="text" onChange={this.changeLinkName}/>
                                    </div>
                                    {
                                        this.state.showCreatePassword ?
                                            <button className="btn btn_small popup__create-btn"
                                                    onClick={this.createPassword}
                                                    value={this.state.name}> Generate password
                                            </button>
                                            :
                                            <div className="show-contacts">
                                                <span className="password-span">Your password is: <span
                                                    id="password">{this.state.password}</span></span>
                                            </div>

                                    }

                                    <div>
                                        <button className="btn btn_small popup__create-btn" onClick={this.createLink}
                                                value={this.state.name}> Create Link
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

export default connectModal({name: 'ShareGalleryModal', destroyOnHide: true})(ShareGalleryModal)