import React, {Component} from 'react';
import ImageLoader from 'react-load-image';
import videoPreview from '../images/preview1.svg'
import {connect} from "react-redux";
import {show} from 'redux-modal'

class Item extends Component {
    preloader() {
        return (<img className="card-img-top"
                     src={videoPreview} alt=""
        />);
    }

    showModal = (price) => {
        this.props.show(this.props.showedModal, {
            itemId: this.props.item.id,
            showButton: this.props.showButton,
            buttonColor: this.props.buttonColor,
            buttonTextColor: this.props.buttonTextColor,
            itemPrice: price
        })
    }

    render() {
        if (this.props.item) {
            if (this.props.modal) {
                this.showModal()
            }
            let preview;
            if (this.props.item.imagePreview) {
                preview = this.props.item.imagePreview
            } else if (this.props.item.thumbnail) {
                preview = this.props.item.thumbnail;
            } else {
                preview = videoPreview
            }
            return (
                <div className="col-6 col-md-3 item__col">
                    <div className="gallery__item" onClick={() => {
                        this.showModal(this.props.item.price)
                    }}>
                        <ImageLoader src={preview}>
                            <img alt={this.props.item.sku} onError={(e) => {
                                e.target.src ={videoPreview};
                            }}/>
                            {this.preloader()}
                        </ImageLoader>
                        <span className="gallery__text">SKU: {this.props.item.sku}</span>
                        <div className="info_mobile_block">
                            <div className="info_block_sku">{this.props.item.sku}</div>
                            <div className="info_block_media">{this.props.item.contents_count} media</div>
                            {this.props.item.price && <div className="info_block_price">${this.props.item.price}</div>}
                        </div>
                    </div>
                </div>
            );
        } else {
            return false;
        }
    }
}

function mapStateToProps(state) {
    const {userData} = state
    return {
        showedModal: userData.showGallery ? "ItemModal" : "UnassignedModal",
    }
}

export default connect(mapStateToProps, {show})(Item);