import React, {Component} from 'react';
import {connect} from "react-redux";
import {NotificationManager} from "react-notifications";
import {copyToClipboard} from "../helpers"
import ApiData from "./ApiData";
import store from "../store";
import {updateShowedComponent} from "../actions/ItemDetailsAction";
import {SHOW_ATTRIBUTES} from "../constants";

class ItemShare extends Component {
    constructor(props) {
        super(props);
        this.apiData = new ApiData()
    }

    componentDidMount() {
        this.setState({name: null, password: null})
    }

    createPassword = () => {
        this.setState({
            password: Math.random().toString(36).slice(-8),
            showCreatePassword: false
        })
    }

    createLink = async () => {
        const {link} = await this.apiData.shareItem({item_id: this.props.itemData.sku_id, password: this.state.password});
        copyToClipboard(link, 'url')
        NotificationManager.success('Sku has been shared');
    }

    handleClose() {
        store.dispatch(updateShowedComponent(SHOW_ATTRIBUTES))
    }

    render() {
        return (
            <>
                {this.state &&
                <>
                    <div className="show-contacts">
                        <button className="btn btn_small popup__create-btn"
                                onClick={this.createPassword}
                                value={this.state.name}> Generate password
                        </button>
                    </div>
                    {
                        this.state.password ?
                            <div className="show-contacts">
                    <span className="password-span">Your password is: <span
                        id="password">{this.state.password}</span></span>
                            </div>
                            :
                            ""
                    }

                    <div>
                        <button className="btn btn_small popup__create-btn"
                                onClick={this.createLink}
                                value={this.state.name}> Create Link
                        </button>
                        <button className="btn btn_small popup__create-btn"
                                onClick={this.handleClose}
                                value={this.state.name}> Close
                        </button>
                    </div>
                </>
                }
            </>
        )
    }
}

function mapStateToProps(state) {
    const {itemDetails} = state
    return {itemData: itemDetails ? itemDetails.details : {}}
}

export default connect(mapStateToProps)(ItemShare);