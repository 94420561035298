import React, {Component} from 'react';
import {groupBy} from "../helpers";
import {connect} from "react-redux";
import {isMobile} from 'react-device-detect';

class ItemAttributes extends Component {
    componentDidMount() {
        this.setState({itemData: this.props.itemData})
    }

    renderAttributes = () => {
        return Object.entries(this.state.itemData.attributes).map(function ([i, attributes]) {
            if (attributes.is_visible) {
                if (Array.isArray(attributes.values)) {
                    const data = Object.entries(groupBy(attributes.values, 'name'))
                    if (data.length && data[0][0] && data[0][1]) {
                        return (data[0][0]!='Price'&&isMobile||!isMobile)&&<div className="shared_item attributes"
                                    key={i}>{data[0][0]}: <span>{data[0][1].join(',')} {attributes.values[0].suffix}</span></div>;
                    }
                } else {
                    return <div className="shared_item attributes"
                                key={i}>{attributes.name}: <span>{attributes.value}</span></div>;
                }
            }
        }, this);
    }

    render() {
        return (
            <div className="attributes">
                {
                    this.state && this.state.itemData &&
                    <>
                        {/*{*/}
                        {/*    this.state && this.state.itemData && this.state.itemData.description &&*/}
                        {/*    <>*/}
                        {/*        <div className="shared_item description-title">*/}
                        {/*            Description:*/}
                        {/*        </div>*/}
                        {/*        <div className="shared_item description">*/}

                        {/*            {this.state.itemData.description}</div>*/}
                        {/*    </>*/}
                        {/*}*/}



                        {this.state.itemData.attributes && Object.entries(this.state.itemData.attributes).length > 0 && this.renderAttributes()}
                        {
                            this.state.itemData.email  && this.state.itemData.visible_parameters.email === 1&&
                            <div className="shared_item attributes">
                                Email: <span>{this.state.itemData.email}</span>
                            </div>
                        }
                        {
                            this.state.itemData.company  && this.state.itemData.visible_parameters.company === 1 &&
                            <div className="shared_item attributes">
                                Company name: <span>{this.state.itemData.company}</span>
                            </div>
                        }
                    </>
                }

            </div>

        )
    }
}

function mapStateToProps(state) {
    const {itemDetails} = state
    return {itemData: itemDetails ? itemDetails.details : {}}
}

export default connect(mapStateToProps)(ItemAttributes);