import axios from 'axios';
import {dataURItoBlob} from "../helpers"
import XMLParser from "react-xml-parser";
import querystring from "querystring"

class ApiData extends Comment {

    getLogo = function (data) {
        return axios({
            url: process.env.REACT_APP_API_URL + '/gallery/logo-url',
            method: 'get',
            params: data
        }).then(response => response.data);
    }
    getContactButton = function (data) {
        return axios({
            url: process.env.REACT_APP_API_URL + '/gallery/get-contact-button',
            method: 'get',
            params: data
        }).then(response => response.data);
    }
    getFilters = function (data) {
        return axios({
            url: process.env.REACT_APP_API_URL + '/catalog-attributes/filters',
            method: 'get',
            params: data
        }).then(response => response.data);
    }
    getItems = function (data) {
        return axios({
            url: process.env.REACT_APP_API_URL + '/catalog-items/list',
            method: 'get',
            params: data
        }).then(response => response.data);
    }
    editItem = function (data, callback) {
        axios({
            url: process.env.REACT_APP_API_URL + '/catalog-items/update',
            method: 'get',
            params: data

        }).then(response => {
            callback(response.data);
        }).catch(err => {
            console.log('err', err);
        })
    }

    updateItem = function (data, sku_id) {
        return axios({
            url: process.env.REACT_APP_API_URL + '/catalog-items/update?sku_id=' + sku_id,
            method: 'post',
            data: data
        }).then(response => response.data);
    }
    addItem = function (data) {
        return axios({
            url: process.env.REACT_APP_API_URL + '/catalog-items/create',
            method: 'post',
            data: data
        }).then(response => response.data)
    }
    getDetails = function (data) {
        return axios({
            url: process.env.REACT_APP_API_URL + '/catalog-items/details',
            method: 'get',
            params: data
        }).then(response => response.data)
    }

    updateOrder = function (data) {
        return axios({
            url: process.env.REACT_APP_API_URL + '/content/update-order?json',
            method: 'post',
            data: data
        }).then(response => response.data);
    }
    shared = function (data) {
        return axios({
            url: process.env.REACT_APP_API_URL + '/catalog-items/shared',
            method: 'get',
            params: data
        }).then(response => response.data);
    }
    shareItems = function (data, url, callback) {
        return axios({
            url: url,
            params: data,
            method: 'get'
        }).then(response => {
            callback(response.data);
        }).catch(err => {
            console.log('err', err);
        })
    }
    shareItem = function (data) {
        return axios({
            url: process.env.REACT_APP_API_URL + '/item/enable-shared-link',
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: querystring.stringify(data),
        }).then(response => response.data)
    }

    showContactInfo = function (data, callback) {
        return axios({
            url: process.env.REACT_APP_API_URL + '/gallery/contact-information',
            method: 'get',
            params: data
        }).then(response => {
            callback(response.data);
        }).catch(err => {
            console.log('err', err);
        })
    }
    uploadContent = async function (data) {
        return axios({
            url: process.env.REACT_APP_API_URL + '/content/get-s3-post-data',
            method: 'post',
            params: data
        }).then(response => response.data)
          .catch((error) => {
            alert('Fail: ' + error.response.data.message)
        })
    }
    storeS3 = async function (data, fileItem, rawLog) {

        var formData = new FormData();
        for (let [name, value] of Object.entries(data.inputs)) {
            formData.append(name, value);
        }
        var fileBlob = dataURItoBlob(rawLog);
        formData.append('file', fileBlob, fileItem.name);
        const returnData = axios({
            url: data.attributes.action,
            method: data.attributes.method,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: formData

        });
        return returnData.then(response => response.data);


    }
    storeContent = async function (data, sku, size, type) {
        var parsedXml = new XMLParser().parseFromString(data);
        var body = {
            type: type,
            s3_object: parsedXml.getElementsByTagName('Key')[0].value,
            bytes_size: size,
            sku: sku,
            video_preview: null,
            create_spin: false
        }
        return axios({
            url: process.env.REACT_APP_API_URL + '/content/store',
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: querystring.stringify(body)
        }).then(response => response.data);
    }

    deleteContent = function (data) {
        return axios({
            url: process.env.REACT_APP_API_URL + '/content/delete',
            method: 'get',
            params: data,
        }).then(response => response.data);
    }

    checkUploadStatus = function (data) {
        return axios({
            url: process.env.REACT_APP_API_URL + '/content/check-uploading-status',
            method: 'get',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params: {sku_id: data}
        }).then(response => response.data);
    }

    createAttribute = function (data) {
        return axios({
            url: process.env.REACT_APP_API_URL + '/catalog-attributes/create',
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: querystring.stringify(data)
        }).then(response => response.data);
    }
    createAttributeValue = function (data) {
        return axios({
            url: process.env.REACT_APP_API_URL + '/catalog-attribute-value/create',
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: querystring.stringify(data)
        }).then(response => response.data);
    }

    deleteItem = function (id, callback) {
        return axios({
            url: process.env.REACT_APP_API_URL + '/catalog-items/delete',
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: querystring.stringify({id: id})
        }).then(response => response.data)
    }

    getUnassigenedContent = async function (data) {
        return axios({
            url: process.env.REACT_APP_API_URL + '/content/list',
            method: 'get',
            params: data
        }).then(response => response.data);
    }

    assginToSku = function (content_id, sku_id) {
        return axios({
            url: process.env.REACT_APP_API_URL + '/content/update-item',
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: querystring.stringify({content_id: content_id, sku_id: sku_id})
        }).then(response => response.data);
    }
    getSpinFrames = function (uid) {
        return axios({
            url: process.env.REACT_APP_API_URL + '/content/get-spin-frames',
            method: 'get',
            params: {
                uid:uid
            }
        }).then(response => response.data).catch( error => error);
    }
}

export default ApiData;