import React from 'react';
import {SortableContainer} from 'react-sortable-hoc';
import SortableImageContainer from './SortableImageContainer'
import store from "../store";

const ImageContainer = SortableContainer((props) => {
    let queued = []
    if (store.getState().uploadQueue) {
        queued = store.getState().uploadQueue.items
    }
    return (
        <div className="row sortable-box">
            {
                props.images ?
                    props.images.map((item, index) =>
                        <SortableImageContainer key={index} index={index}
                                                image={item.thumbnail} id={item.id}
                                                name={item.name}
                                                type={item.type} sku_id={props.sku_id}
                                                download_url={item.download_url}
                                                status={statusChecker(item, queued, props)}

                        />)
                    :
                    ""
            }
        </div>
    );
})
const statusChecker = (item, queued, props) => {
    let status = 'done';
    if (props.uploadQueue) {
        if (item) {
            const it = queued.filter(it => it.id === item.id);
            if (it.length) {
                status = it[0].status
            } else {
                status = 'done'
            }
        }
    }
    return status
}
export default ImageContainer
