import React from 'react';
import {render} from 'react-dom';
import {Router, Route, browserHistory} from 'react-router';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-select2-wrapper/css/select2.css';
import './style.css';
import './index.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import Master from './components/Master';
import GalleryWrapper from './components/GalleryWrapper';
import SharedItem from './components/SharedItem';
import RapnetItem from './components/RapnetItem';
import * as serviceWorker from './serviceWorker';

import {Provider} from "react-redux";
import store from "./store";
import ReactGA from "react-ga";

ReactGA.initialize('G-0RG9BRZWEW');
render(
    <Provider store={store}>
        <Router history={browserHistory}>
            <Route path="/s/:item_uid" component={SharedItem}>
            </Route>
            <Route path="/c/:item_uid" isContent={1} component={SharedItem} >
            </Route>
            <Route path="/r/:item_uid" isContent={1} isRapnet={1} component={RapnetItem} >
            </Route>
            <Route path="/g/:gallery_uid" component={GalleryWrapper}>
            </Route>
            <Route path="/" component={Master}>
                <Route path="/:gallery" component={GalleryWrapper} key={'GalleryWrapper'}>
                </Route>
            </Route>
        </Router>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
