import {combineReducers} from "redux";
import ItemReducer from "./itemReducer";
import {reducer as modal} from 'redux-modal'
import UploadStatusReducer from "./UploadStatusReducer";
import queueReducer from "./queueReducer";
import itemDetailsReducer from "./itemDetailsReducer";
import mainReducer from "./mainReducer";

const rootReducer = combineReducers({
    items: ItemReducer,
    uploadStatus: UploadStatusReducer,
    uploadQueue: queueReducer,
    itemDetails: itemDetailsReducer,
    userData: mainReducer,
    modal
});

export default rootReducer;