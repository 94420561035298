import {
    UPDATE_DETAILS,
    SET_CURRENT_CONTENT,
    UPDATE_SELECTED_DATA,
    UPDATE_SHOWED_COMPONENT,
    UPDATE_STRUCTURE,
    ADD_ATTRIBUTE_VALUE, UPDATE_ATTRIBUTES, REMOVE_CONTENT, REMOVE_DETAILS, ADD_CONTENT, UPDATE_CONTENTS, REMOVE_PROP
} from "./actionTypes";

export function updateDetails(details) {
    return {
        type: UPDATE_DETAILS,
        payload: {details: details}
    };
}

export function setCurrentContent(contentId) {
    return {
        type: SET_CURRENT_CONTENT,
        payload: {contentId: contentId}
    };
}

export function updateData(data) {
    return {
        type: UPDATE_SELECTED_DATA,
        payload: {data: data}
    };
}

export function updateShowedComponent(component, props = {}) {
    return {
        type: UPDATE_SHOWED_COMPONENT,
        payload: {component: component, props: props}
    };
}

export function updateStructure(structure) {
    return {
        type: UPDATE_STRUCTURE,
        payload: {structure: structure}
    };
}

export function updateAttributes(attributes) {
    return {
        type: UPDATE_ATTRIBUTES,
        payload: {attributes: attributes}
    };
}

export function addAttributeValue(attributeId, data) {
    return {
        type: ADD_ATTRIBUTE_VALUE,
        payload: {attributeId: attributeId, data: data}
    };
}

export function removeContent(contentId) {
    return {
        type: REMOVE_CONTENT,
        payload: contentId
    }
}

export function addContent(content) {
    return {
        type: ADD_CONTENT,
        payload: content
    }
}

export function removeDetails() {
    return {
        type: REMOVE_DETAILS,
    }
}

export function updateContents(contents) {
    return {
        type: UPDATE_CONTENTS,
        payload: contents
    }
}

export function removeProp(name) {
    return {
        type: REMOVE_PROP,
        payload: name
    }
}
