import {UPDATE_USER_DATA, UPDATE_FILTERS} from "../actions/actionTypes";

const defaultState = null;

export default (state = defaultState, action = {}) => {
    switch (action.type) {
        case UPDATE_FILTERS: {
            return {
                ...state,
                filters: action.payload
            }
        }
        case UPDATE_USER_DATA: {
            return {
                ...state,
                ...action.payload
            }
        }
        default:
            return state;
    }
};