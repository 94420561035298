import React from "react";
import {updateItemsList} from "../actions/itemActions";
import store from "../store";
import ApiData from "./ApiData";
import {updateUserData} from "../actions/MainActions";
import {connectModal, hide, show} from "redux-modal";
import {connect} from "react-redux";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css'
import {generateUrlData, getFiltersFromUrl, halfSplice} from "../helpers";
import {createBrowserHistory} from "history";
import $ from "jquery"
import ReactGA from "react-ga";

window.$ = $
const history = createBrowserHistory();
const queryString = require('query-string');

class FiltersModal extends React.Component {
    constructor(props) {
        super(props);
        this.apiData = new ApiData()
        this.state = {
            filters: {},
            value: {min: 0, max: 9999999},
            total: store.getState().userData.total
        };
        this.applyFilters = this.applyFilters.bind(this);
        this.isActive = this.isActive.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.changeRange = this.changeRange.bind(this);
        this.changeInput = this.changeInput.bind(this);
        this.delayTimer = null;
        $.fn.highlight = function (pat) {
            function innerHighlight(node, pat) {
                var skip = 0;
                if (node.nodeType == 3) {
                    let pos = node.data.toUpperCase().indexOf(pat);
                    if (pos >= 0) {
                        let spannode = document.createElement('span');
                        spannode.className = 'highlight';
                        let middlebit = node.splitText(pos);
                        let endbit = middlebit.splitText(pat.length);
                        let middleclone = middlebit.cloneNode(true);
                        spannode.appendChild(middleclone);
                        middlebit.parentNode.replaceChild(spannode, middlebit);
                        skip = 1;
                    }
                } else if (node.nodeType == 1 && node.childNodes && !/(script|style)/i.test(node.tagName)) {
                    for (let i = 0; i < node.childNodes.length; ++i) {
                        i += innerHighlight(node.childNodes[i], pat);
                    }
                }
                return skip;
            }

            return this.each(function () {
                innerHighlight(this, pat.toUpperCase());
            });
        };

        $.fn.removeHighlight = function () {
            function newNormalize(node) {
                for (let i = 0, children = node.childNodes, nodeCount = children.length; i < nodeCount; i++) {
                    let child = children[i];
                    if (child.nodeType == 1) {
                        newNormalize(child);
                        continue;
                    }
                    if (child.nodeType != 3) {
                        continue;
                    }
                    let next = child.nextSibling;
                    if (next == null || next.nodeType != 3) {
                        continue;
                    }
                    let combined_text = child.nodeValue + next.nodeValue;
                    let new_node = node.ownerDocument.createTextNode(combined_text);
                    node.insertBefore(new_node, child);
                    node.removeChild(child);
                    node.removeChild(next);
                    i--;
                    nodeCount--;
                }
            }

            return this.find("span.highlight").each(function () {
                let thisParent = this.parentNode;
                thisParent.replaceChild(this.firstChild, this);
                newNormalize(thisParent);
            }).end();
        };
    }

    componentWillMount = async () => {
        const filters = store.getState().userData.filters
        const total = await this.getTotal(filters);
        console.log(total)
        this.setState({
            filters: filters,
            total: total
        })
    }

    componentWillReceiveProps(props) {
        this.setState({
            filters: props.filters,
            keyword: props.keyword,
            gallery_uid: props.gallery_uid,
        })
    }

    getFilters = async () => {
        if (store.getState().userData.filterAttributes === undefined) {
            let reqParams = {}
            if (this.props.keyword) {
                reqParams.keyword = this.props.keyword
            }
            if (this.props.gallery_uid) {
                reqParams.guid = this.props.gallery_uid
            }
            reqParams.filtersEnabled = this.props.filtersEnabled ? 1 : 0
            const {attributeFilters, mediaFilters, visibleAttrCount, price} = await this.apiData.getFilters(reqParams);
            const data = {
                attributeFilters: halfSplice(Object.entries(attributeFilters)),
                filterAttributes: attributeFilters,
                mediaFilters: mediaFilters,
                visibleAttrCount: visibleAttrCount,
                priceAttr: price
            }
            store.dispatch(updateUserData(data))
            this.setState({...data})
        } else {
            const {attributeFilters,filterAttributes,mediaFilters,visibleAttrCount,priceAttr} = store.getState().userData
            this.setState({
                attributeFilters: attributeFilters,
                filterAttributes: filterAttributes,
                mediaFilters: mediaFilters,
                visibleAttrCount: visibleAttrCount,
                priceAttr: priceAttr
            })
        }
    };
    changeFilter = async (value, name, type = null,format_type) => {
        let filters = this.state.filters
        if (type == 'attr') {
            let values = filters[name] ? filters[name].value : []
            if (values.indexOf(value) !== -1) {
                values = values.filter(it => it !== value)
            } else {
                values.push(value)
            }
            filters[name] = {value: values, type: 'attr',format_type:format_type};

        } else if (type == 'text_input') {
            filters[name] = {value: value,type:'attr',format_type:format_type };
        } else {
            filters[name] = {value: value.min + "," + value.max, type: 'attr',format_type:format_type};
        }
        let reqParams = generateUrlData(filters)
        reqParams['keyword'] = this.props.keyword;
        reqParams['gallery_uid'] = this.props.gallery_uid;
        reqParams['new_catalog'] = 1;
        reqParams['get_total'] = 1;

        const {total} = await this.apiData.getItems(reqParams);
        this.setState({filters: filters,total:total})
        store.dispatch(updateUserData({filters: filters}));



    }

    clearFilters = async (name, type = null, code = null) => {
        const newFilters = this.state.filters
        delete newFilters[name]
        const total = await this.getTotal(newFilters)
        this.setState({filters: {...newFilters}, total: total});
        store.dispatch(updateUserData({filters:newFilters}));
    }
    clearAllFilters = async () => {
        const total  = await this.getTotal({})
        this.setState({filters: {}, total: total})
        store.dispatch(updateUserData({filters: {}}));



    }

    getTotal = async (filters) => {

        let reqParams = generateUrlData(filters)
        reqParams['keyword'] = this.props.keyword;
        reqParams['gallery_uid'] = this.props.gallery_uid;
        reqParams['new_catalog'] = 1;
        reqParams['get_total'] = 1;
        const {total} = await this.apiData.getItems(reqParams);
        return total
    }
    applyFilters = async () => {
        let reqParams = generateUrlData(this.state.filters)
        reqParams['keyword'] = this.props.keyword;
        reqParams['gallery_uid'] = this.props.gallery_uid;
        reqParams['new_catalog'] = 1;
        const response = await this.apiData.getItems(reqParams);
        const {password, hasMore, user_id, total} = response
        if (password) {
            this.setState({requestPassword: true})
            this.props.show('PasswordModal');
            store.dispatch(updateUserData({
                wrongPassword: this.state.passwordChecked ? true : false,
                stopChecking: true,
            }))
        } else {
            this.props.hide('PasswordModal');
            store.dispatch(updateItemsList(response, true));
            let search = "";
            Object.entries(reqParams).map((v) => {
                if (v[0] != 'gallery_uid' && v[0] !== 'new_catalog'&& v[0] !== 'keyword' && v[1].length) {
                    search += v[0] + "=" + v[1] + "&"
                }
            })
            history.push({
                pathname: window.location.pathname,
                search: search
            });
            ReactGA.pageview(window.location.pathname + window.location.search)

        }
        store.dispatch(updateUserData({
            getNewItems: false,
            clearOld: false,
            page: 1,
            stopChecking: false,
            reqParams: reqParams,
            hasMore: hasMore,
            init: false
        }));
        this.props.handleHide()
    }
    findFilters = (event) => {
        let searchTerm = event.target.value;

        // remove any old highlighted terms
        $('.modal_catalog-filters').removeHighlight();

        // disable highlighting if empty
        if (searchTerm) {
            // highlight the new term
            $('.modal_catalog-filters').highlight(searchTerm);
            if ($('.highlight').length) {
                $('.modal, .modal_catalog-filters').animate({scrollTop: $('.highlight:first').offset().top}, 1000);
            }
        }
    }
    isActive = (name, value, format_type = 'list') => {
        if (format_type == 'input') {
            if (this.state.filters[name] !== undefined) {
                return this.state.filters[name].value.length>0;
            } else {
                return false;
            }
        } else if (this.state.filters != undefined && this.state.filters[name] && this.state.filters[name].value) {
            return this.state.filters[name].value.filter(val => val == value).length > 0
        } else {
            return false;
        }
    }

    handleClose = async () => {
        let {reqParams,filters} = getFiltersFromUrl(queryString.parse(window.location.search, {ignoreQueryPrefix: true}));
        store.dispatch(updateUserData({filters:filters}))
        this.props.handleHide();
    }

    async componentDidMount() {
        await this.getFilters()
    }

    changeRange = (value, name, type = null,format_type) => {
        let {filters} = this.state
        filters[name] = {value: value.min + "," + value.max, type: 'attr',format_type:format_type};
        this.setState({filters:filters})
    }

    changeInput = (value, name, type = null,format_type) => {
        clearTimeout(this.delayTimer);
        let self = this;
        // this.delayTimer = setTimeout(function() {
         this.changeFilter(value, name, type,format_type)
    // }, 1000);
    }

    renderAttributes(attributes) {
        return attributes.map((v, i) => {
            let values = this.state.filters[v[1].attr_id]?this.state.filters[v[1].attr_id].value:""
            if (typeof values !== "object" /*&& v[1].attr_type === 'number' && v[1].format_type == 'input' */) {
                values = values.split(',')
            }
            return (
                <div className="filter" key={i}>
                    <label>{v[1].label.toUpperCase()}
                        <a id={"clear-" + v[1].attr_id}
                           data-id={v[1].attr_id}
                           className={"pull-right btn_clear " + (this.hasActiveValues(v[1].attr_id, v[1].attr_type, v[1].format_type) ? 'active' : 'no-active')}
                           onClick={() => {
                               if (this.hasActiveValues(v[1].attr_id, v[1].attr_type, v[1].format_type)) {
                                   this.clearFilters(v[1].attr_id, v[1].attr_type, v[0])
                               }
                           }}
                        >
                            Clear</a>
                    </label>
                    {
                        v[1].attr_type !== 'number' && !v[1].values.length && v[1].format_type!=='input' &&

                        <>No values for this attribute</>
                    }
                    {
                        v[1].attr_type === 'number' && (v[1].format_type === 'list' || v[1].format_type === 'input') &&
                        <div className="range-filter">
                            <InputRange
                                maxValue={v[1].max}
                                minValue={0}
                                id={v[0]}
                                value={{
                                    min: this.state.filters[v[1].attr_id] ? values[0] : 0,
                                    max: this.state.filters[v[1].attr_id] ? values[1] : v[1].max
                                }}
                                onChange={value => this.changeRange(value, v[1].attr_id, 'number',v[1].format_type)}
                                onChangeComplete={value => this.changeFilter(value, v[1].attr_id, 'number',v[1].format_type)}
                            />
                        </div>
                    }
                    {
                        v[1].attr_type === 'text' && v[1].format_type === 'input' ? <div className="text-filter">
                                <input
                                    className="filter__input"
                                    value={values}
                                    onChange={value => this.changeInput(value.target.value, v[1].attr_id, 'text_input',v[1].format_type)}
                                />
                                {this.hasActiveValues(v[1].attr_id, v[1].attr_type, v[1].format_type) &&
                                <div className="clear__input" aria-hidden="true" id="clear-sku-field" onClick={()=>this.clearFilters(v[1].attr_id, v[1].attr_type, v[0])}></div>}
                            </div>
                            :
                            v[1].values && v[1].values.map((value, index) => {
                                return (
                                    <div className="item-filter" key={index}>
                                        <a href="#"
                                           className={"btn_filter " + (this.isActive(value.attribute_id, value.value, v[1].format_type) ? "btn_filter_selected" : "")}
                                           onClick={() => {
                                               this.changeFilter(value.value.toString(), value.attribute_id,'attr',v[1].format_type)
                                           }}
                                        >
                                            {value.label}
                                        </a>
                                    </div>

                                )
                            })
                    }
                </div>
            )
        })
    }

    hasActiveValues = (name, type, format_type = 'list') => {
        const values = this.state.filters[name] ? this.state.filters[name].value : []
        if (values.length) {
            if (type === 'number' && format_type === 'list') {
                let valueArr = values.split(',')
                if (
                    this.state.filterAttributes[name] &&
                    this.state.filterAttributes[name].max === valueArr[1] &&
                    this.state.filterAttributes[name].min === 0
                ) {
                    return false
                } else {
                    return true
                }
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    render() {
        let priceValues
        if (this.state.filters.price && this.state.filters.price.value) {
            priceValues = this.state.filters.price.value
            if (typeof priceValues !== "object") {
                priceValues = priceValues.split(',')
            }
        }
        return (
            this.state.attributeFilters && this.state.mediaFilters ?
                <Modal isOpen={true} toggle={this.handleClose} size="lg">
                    <ModalHeader>
                        <span>Filters</span>
                        <input type="text" className="form-control price" id="search-attributes"
                               placeholder="Search" onKeyUp={this.findFilters}/>
                        <button type="button" className="close__popup right__side   "
                                onClick={() => this.handleClose()}><span
                            aria-hidden="true">×</span></button>
                    </ModalHeader>
                    <ModalBody>
                        <div id="filters-form" method="get">
                            <div className="modal-body">
                                <div className="modal_max-height-scroll">
                                    <div className="modal_catalog-filters">
                                        <div>
                                            <div className="filter">
                                                <label>SHOW ITEMS WITH<a id="clear-type-filter"
                                                                         data-id="type-filter"
                                                                         className={"pull-right btn_clear " + (this.hasActiveValues('type', 'attr') ? 'active' : 'no-active')}
                                                                         onClick={() => {
                                                                             if(this.hasActiveValues('type', 'attr')) {
                                                                                 this.clearFilters('type')
                                                                             }
                                                                         }}> Clear</a></label>
                                                {
                                                    Object.entries(this.state.mediaFilters).map((v, i) => {
                                                        return (
                                                            <div className="item-filter" key={i}>
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-checkbox"
                                                                    checked={this.isActive('type', v[0],'list')}
                                                                />
                                                                <label className="type__filter"
                                                                       htmlFor={"type-" + v[0]}
                                                                       onClick={() => {
                                                                           this.changeFilter(v[0], 'type','attr','list')
                                                                       }}
                                                                >{v[1]}</label>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>

                                            {this.renderAttributes(this.state.attributeFilters[0])}
                                        </div>
                                        <div>
                                            {this.renderAttributes(this.state.attributeFilters[1])}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="row filters_footer">
                            <div className="col-xs-12 col-md-6 pt-10 text-left d-none d-sm-block">
                                <a href="#" className="clear_all" onClick={() => {
                                    this.clearAllFilters()
                                }}><b>Clear filters</b></a>
                            </div>
                            <div className="col-xs-12 col-md-6 filter_btn_block">
                                    <a className="btn btn-default btn_white btn_small filter_btn cancel"
                                       onClick={this.handleClose}>Cancel</a>
                                    <button type="submit" className="btn btn-primary btn_small filter_btn"
                                            onClick={this.applyFilters}>Show&nbsp;<span
                                        id="total-results">{this.state.total}</span>&nbsp;items
                                    </button>
                            </div>
                        </div>

                    </ModalFooter>
                </Modal>
                :
                <></>
        )

    }
}

const mapStateToProps = state => {
    const {userData} = state
    return {
        filters: userData.filters ? userData.filters : {},
        keyword: userData.keyword,
        gallery_uid: userData.gallery_uid,
        total: userData.total
    }
}
export default connectModal({
    name: 'FiltersModal',
    destroyOnHide: true
})(connect(mapStateToProps, {show, hide})(FiltersModal))