import React, {Component} from 'react';
import {components} from "react-select";
import Creatable from "react-select/creatable/dist/react-select.esm";
import {DEFAULT_ATTRIBUTES} from "../defaultattributes";
import ApiData from "./ApiData";
import AddAttributeValue from "./AddAttributeValue";
import AddAttribute from "./AddAttribute";
import {connect} from "react-redux";
import store from "../store"
import {
    addAttributeValue, removeProp,
    updateAttributes,
    updateData,
    updateShowedComponent,
    updateStructure
} from "../actions/ItemDetailsAction"
import {ADD_ATTRIBUTE, SHOW_ATTRIBUTES} from "../constants";
import {colourStyles} from "../constants"
import {addItem, removeItem, updateThumbnail} from "../actions/itemActions";
import {NotificationManager} from 'react-notifications';

class EditSku extends Component {
    constructor(props, context) {
        super(props, context);
        this.onChangePrice = this.onChangePrice.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangeSku = this.onChangeSku.bind(this);
        this.onChangeSelect = this.onChangeSelect.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.apiData = new ApiData()
        this.ValueContainer = ({children, getValue, ...props}) => {
            const {selectProps} = props;
            return (
                <components.ValueContainer {...props}>

                    <div className="custom-selected">
                        <ul>
                            {
                                getValue().length ? getValue().map(function (item) {
                                    return <li className="selected-li" key={item.value}>
                                        <span onClick={() => {
                                            this.removeSelectedAttribute(selectProps.attributeId, item.value)
                                        }}>x</span>
                                        <span>{item.name} </span>
                                    </li>
                                }, this) : selectProps.placeholder
                            }
                        </ul>

                        {React.cloneElement(children[1])}
                    </div>
                </components.ValueContainer>
            );
        };
        this.state = {
            notValid: false,
            selected: {sku: null, price: null, description: null, attributes: {}},
            structure: {}
        }
    }


    componentDidMount() {
        this.apiData.editItem({
            sku_id: this.props.selected.sku_id,
            forUpdate: true,
        }, ({structure}) => {
            store.dispatch(updateStructure(structure))
            let {selected} = this.props;
            if (!Object.keys(selected).length) {
                selected = {
                    sku: null,
                    sku_id: null,
                    price: null,
                    description: null,
                    attributes: {}
                }
            }
            this.setState({
                structure: structure,
                selected: selected,
                notValid: false,
                name: null,
            })
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            ...this.state,
            selected: {
                ...this.state.selected,
                attributes: {...nextProps.selected.attributes},
            },
        })
    }

    onChangeSelect = async (value, e) => {
        if (value && value['__isNew__']) {
            const {success, data, errors} = await this.apiData.createAttributeValue({
                attribute_id: e.id,
                'UserCatalogItemAttributeValues[name]': value.value
            });
            if (success) {
                store.dispatch(addAttributeValue(e.id, data))
            } else {
                if (errors['usercatalogitemattributevalues-name'] !== undefined) {
                    this.setState({
                        error_message: errors['usercatalogitemattributevalues-name'][0],
                        not_valid: true
                    })
                }
            }
            return true
        }
        if (value && value.name === "Add new attribute value") {
            store.dispatch(updateShowedComponent('addAttributeValue', {attributeId: e.id}))
            return true;
        }
        let {attributes} = this.state.selected;
        if (!attributes[e.id]) {
            attributes[e.id] = {
                is_visible: true,
                values: []
            }
        }
        if (!e.isMulti) {
            attributes[e.id].values=[];
            attributes[e.id].values.push({
                name: e.name,
                value: value ? value.name : null,
                value_id: value ? value.value : null,
            });
        } else {
            if (attributes[e.id] && attributes[e.id].values.filter(it => it.value_id === value.value).length) {
                attributes[e.id] = attributes[e.id].values.filter(it => it.value_id !== value.value)
            } else {
                attributes[e.id].values.push({
                    name: e.name,
                    value: value ? value.name : null,
                    value_id: value ? value.value : null,
                });
            }
        }
        store.dispatch(updateAttributes(attributes))
    };

    removeSelectedAttribute(attributeId, value) {
        let selected = this.state.selected;
        selected.attributes[attributeId].values = selected.attributes[attributeId].values.filter(it => it.value_id !== value)
        this.setState({
            selected: selected
        })
    }

    onChangeAttributeVisibility = (e) => {
        let attributes = Object.assign({}, this.state.selected.attributes);
        if (e.target.attributes['data-attribute-id']) {
            if (!attributes[parseInt(e.target.attributes['data-attribute-id'].value)]) {
                attributes[parseInt(e.target.attributes['data-attribute-id'].value)]={
                    values:[]
                };
            }
            attributes[parseInt(e.target.attributes['data-attribute-id'].value)].is_visible = e.target.checked ? 1 : 0
            store.dispatch(updateAttributes(attributes))
        }
    }

    getSelected(values, selected) {
        if (values) {
            if (Array.isArray(selected)) {
                return values.filter(
                    function (e) {
                        if (this && this.length) {
                            return this.indexOf(e.value) !== -1;
                        }
                    }, selected)
            } else {
                return values.find(op => {
                    if (selected) {
                        return op.value === selected.value_id
                    }
                })
            }

        }
    }

    renderSelects() {
        return (
            Object.entries(this.state.structure).map(([index, attribute]) => {
                let selected;
                if (this.state.selected.attributes[index]) {
                    if (Array.isArray(this.state.selected.attributes[index].values)) {
                        selected = this.state.selected.attributes[index] ? this.state.selected.attributes[index].values.map(it => it.value_id) : []
                    } else {
                        selected = this.state.selected.attributes[index].values;
                    }
                } else {
                    selected = []
                }
                return (
                    <div className="form-group" key={index}>
                        {
                            attribute.code !== 'price' &&
                            <>
                                <Creatable
                                    ref={attribute.label}
                                    value={this.getSelected(attribute.values, selected)}
                                    onChange={(e) => this.onChangeSelect(e, {
                                        name: attribute.label,
                                        id: attribute.id,
                                        is_visible: attribute.is_visible,
                                        isMulti: attribute.is_multiple_choice ? true : false
                                    })}
                                    getOptionLabel={option => !option.__isNew__ ? option.name : "Not found. Create new value?"}
                                    getOptionValue={option => option.value}
                                    options={attribute.values ? [{
                                        value: "",
                                        name: "Add new attribute value"
                                    }].concat(attribute.values) : []}

                                    isMulty={attribute.is_multiple_choice ? true : false}
                                    attributeId={attribute.id}
                                    styles={colourStyles}
                                    components={attribute.is_multiple_choice ? {ValueContainer: this.ValueContainer} : {}}
                                />
                                {
                                    DEFAULT_ATTRIBUTES.indexOf(attribute.code) === -1 &&
                                    <>
                                        <label className="visibility_label"
                                               htmlFor={"attb_visibiliti_" + attribute.id}> Show to
                                            users</label>
                                        <input id={"attb_visibiliti_" + attribute.id} type="checkbox"
                                               data-attribute-id={attribute.id}
                                               checked={this.state.selected.attributes[index] ? this.state.selected.attributes[index].is_visible : true}
                                               onChange={this.onChangeAttributeVisibility}/>
                                    </>
                                }
                            </>
                        }
                    </div>
                )
            })
        )
    }

    onChangeSku = (e) => {
        let selected_state = this.state.selected;
        selected_state['sku'] = e ? e.target.value : null;
        this.setState({
            selected: selected_state
        })
    };
    onChangeDescription = (e) => {
        let selected_state = this.state.selected;
        selected_state.description = e ? e.target.value : null;
        this.setState({
            selected: selected_state
        })
    };

    onChangePrice = (e) => {
        let selected_state = this.state.selected;
        selected_state.price = e ? e.target.value : null;
        this.setState({
            selected: selected_state
        })
    };
    updateItem = async () => {
        let formData = {};
        let itemAttributes = {};
        let itemData = this.state.selected;
        let userForm = {
            sku_id: itemData.sku_id,
            sku: itemData.sku,
            price: itemData.price,
            description: itemData.description
        };
        if (itemData.attributes) {
            Object.entries(itemData.attributes).map(([i, attribute]) => {
                if (!itemAttributes[i]) {
                    itemAttributes[i] = []
                }
                if (Array.isArray(attribute.values)) {
                    itemAttributes[i] = attribute.values.map(it => it.value_id)
                } else {
                    itemAttributes[i] = attribute.values.value_id;
                }
            })
        }

        let attributesVisibility = {};
        let selectedAttributes = {}
        Object.entries(this.state.structure).map(([i, attribute]) => {
            attributesVisibility[attribute.id] = attribute.is_visible
            selectedAttributes[attribute.id] = {is_visible: attribute.is_visible, values: itemAttributes[attribute.id]}
        })
        formData['UserCatalogItemForm'] = userForm;
        formData['itemAttributes'] = itemAttributes;
        formData['attributesVisibility'] = attributesVisibility;
        if (!this.props.isNew) {
            const {success, message} = await this.apiData.updateItem(formData, itemData.sku_id);
            if (success === true) {
                store.dispatch(updateData(this.state.selected))
                NotificationManager.success(message)
            } else {
                this.setState({error_message: message.sku, notValid: true})
            }
        } else {
            formData['content_id'] = this.props.currentContent;
            formData['old_sku'] = this.props.oldSku;
            const {success, selected, message, oldSku} = await this.apiData.addItem(formData, itemData.sku_id);
            if (success === true) {
                let itemData = this.state.selected;
                itemData.contents = itemData.contents.filter(it => it.id === this.props.currentContent)
                store.dispatch(updateData(itemData))
                store.dispatch(addItem({
                    sku: selected.sku,
                    id: selected.sku_id,
                    imagePreview: selected.contents ? selected.contents[0].thumbnail : null
                }))
                if (oldSku.remove) {
                    store.dispatch(removeItem(this.props.oldSku))
                }
                if (oldSku.preview) {
                    store.dispatch(updateThumbnail(oldSku.preview, this.props.oldSku))
                }
                NotificationManager.success(message)

            } else {
                this.setState({error_message: message.sku, notValid: true})
            }

        }

    };

    cancelEdit = () => {
        if(this.props.oldSkuData) {
            store.dispatch(updateData(this.props.oldSkuData))
            store.dispatch(removeProp('oldSkuData'))
        }
        store.dispatch(updateShowedComponent(SHOW_ATTRIBUTES))
    }

    addNewAttribute = () => {
        store.dispatch(updateShowedComponent(ADD_ATTRIBUTE))
    }
    addAttribute = () => {
        var structure = this.state.structure;
        this.apiData.createAttribute({
            'UserCatalogItemAttributes[name]': this.state.newAttribute
        }, (response) => {
            if (response.success) {
                structure[response.data.id] = {
                    id: response.data['id'] ? response.data['id'] : null,
                    label: response.data['name'] ? response.data['name'] : null,
                    code: response.data['code'] ? response.data['code'] : null,
                    values: [],
                    type: 'text',
                    is_visible: response.data.is_visible
                }
                this.setState({
                    structure: structure,
                    newAttribute: "",
                    notValid: false,
                    error_message: false

                });
            } else {
                if (response.errors['usercatalogitemattributes-name'] !== undefined) {
                    this.setState({
                        error_message: response.errors['usercatalogitemattributes-name'][0],
                        notValid: true
                    })
                }
            }
        })
    }

    render() {
        return (
            <div>
                <div
                    className={this.state.notValid ? 'form-group notValid' : "form-group"}>
                    <input className="form-control" required type="text"
                           value={this.state.selected.sku} name="sku"
                           onChange={this.onChangeSku} placeholder="Sku"
                           maxLength={255}/>
                </div>
                <span className="error-message">{this.state.error_message}</span>

                {this.renderSelects()}

                <div className="form-group">
                    <a href="#" className="add_new_attribute"
                       onClick={this.addNewAttribute}>Add new attribute</a>
                </div>
                <div className="form-group">
                    <input className="form-control" type="text" name="price"
                           value={this.state.selected.price}
                           onChange={this.onChangePrice} placeholder="Price"
                           maxLength={255}/>
                </div>
                <div className="form-group">
                    <input className="form-control" type="text" name="description"
                           value={this.state.selected.description}
                           onChange={this.onChangeDescription}
                           placeholder="Description"
                           maxLength={255}/>
                </div>
                <div className="form-group edit-buttons">
                    <div className="show-contacts">
                        <button
                            className="btn btn_white btn_border btn_small btn_shadow_none"
                            onClick={this.cancelEdit}>Cancel
                        </button>
                    </div>
                    <div className="show-contacts">
                        <button
                            className="btn btn_white btn_border btn_small btn_shadow_none"
                            onClick={this.updateItem}>{!this.props.isNew ? "Update" : "Create"}
                        </button>
                    </div>
                </div>
                {this.state.addAttributeValue && <AddAttributeValue/>}
                {this.state.addAttribute && <AddAttribute/>}
            </div>
        )
    }
}

const mapStateToProps = state => {
    const {itemDetails} = state
    return {
        isNew: itemDetails ? itemDetails.props.isNew : {},
        selected: itemDetails ? itemDetails.details : {},
        structure: itemDetails ? itemDetails.structure : {},
        currentContent: itemDetails.props.currentContent,
        oldSku: itemDetails.props.oldData ? itemDetails.props.oldData.sku_id : null,
        oldSkuData: itemDetails.props.oldData ? itemDetails.props.oldData : null,
    }
}

export default connect(mapStateToProps)(EditSku);