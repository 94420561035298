import React, {Component} from 'react';

class Master extends Component {
    constructor(props) {
        super(props)

        this.state = {
            value: '',
            keywords: []
        }
    }

    render() {
        return (
            <main className="main">
                {this.props.children}
            </main>
        )
    }
}

export default Master;