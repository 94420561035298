import React from 'react';
import ImageGallery from 'react-image-gallery';
import videoPreview from '../images/preview1.svg'
import store from "../store";
import {setCurrentContent} from "../actions/ItemDetailsAction";
import {connect} from "react-redux";
import ApiData from "./ApiData";
import SpriteSpin from "./SpriteSpin";
import { Line } from 'rc-progress';

const TYPE_IMAGE = 'image';
const TYPE_VIDEO = 'video';
const TYPE_SPIN = 'spin';

class ModalContentGallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showIndex: false,
            showBullets: false, //props.showThumbnails,
            infinite: true,
            showThumbnails: props.showThumbnails,
            showFullscreenButton: false,
            showGalleryFullscreenButton: false,
            showPlayButton: false,
            showGalleryPlayButton: false,
            showNav: true,
            isRTL: false,
            slideDuration: 450,
            slideInterval: 2000,
            thumbnailPosition: 'bottom',
            showVideo: {},
            contents: [],
            is_owner: props.is_owner,
            contents_count:this.props.contents_count
        };
        this.onSpinProgress = this.onSpinProgress.bind(this);
        this.apiData = new ApiData()
    }

    componentDidMount = () => {
        this.setState({
            images: []
        })
        this.setState({
            contents: this.props.contents,
            showSpinIndex : this.props.contents.length && this.props.contents[0].type === 'spin' ? 0 : null,
            noImages:this.props.noImages
        })
        this.renderContents(this.props.contents)
    }


    _onSlide(index) {
        if (this.spinIndexes.indexOf(index) > -1) {
            this.setState({
                showSpinIndex: index
            });
        } else {
            this.setState({
                showSpinIndex: null
            });
        }
        this.setState({
            loading: true
        });
        this._resetVideo();
        store.dispatch(setCurrentContent(this.state.images[index].id))
    }

    renderContents  =  async (contents) => {
        let images = [];
        let self = this;
        if (contents.length) {
            images = await Promise.all(contents.map(async (content, i) => {
                let result = {};
                if (TYPE_IMAGE === content.type) {
                    result = {
                        original: content.thumbnail,
                        thumbnail: content.thumbnail,
                        type: TYPE_IMAGE,
                        id: content.id
                    };
                } else if (TYPE_VIDEO === content.type) {
                    result = {
                        thumbnail: content.thumbnail ? content.thumbnail : videoPreview,
                        original: content.thumbnail ? content.thumbnail : videoPreview,
                        embedUrl: content.url,
                        renderItem: self._renderVideo.bind(self,content),
                        type: TYPE_VIDEO,
                        id: content.id
                    };
                } else if (TYPE_SPIN === content.type) {
                    const frames = await self.apiData.getSpinFrames(content.uid)
                    if (frames !== undefined &&frames.length) {
                        result = {
                            index: i,
                            thumbnail: content.thumbnail ? content.thumbnail : videoPreview,
                            original: content.thumbnail ? content.thumbnail : videoPreview,
                            embedUrl: content.url,
                            type: TYPE_SPIN,
                            id: content.id
                        };
                        result.renderItem = self._renderSpin.bind(self,frames,content.id);
                    } else {
                        result = {
                            original: videoPreview,
                            thumbnail: videoPreview,
                            type: TYPE_IMAGE,
                            id: content.id,
                            emptySpin: true
                        };
                        result.renderItem = self._renderEmptySpin.bind(self)
                    }

                }

                return result;
            }));
            self.setState({
                images: images
            });
            if (images.length) {
                store.dispatch(setCurrentContent(images[0].id));
                this.spinIndexes = contents.map(function (content, i) {
                    if ('spin' === content.type) {
                        return i;
                    }
                    return false;
                });
                self._resetVideo();
            }
        }
    }

    _handleInputChange(state, event) {
        this.setState({[state]: event.target.value});
    }

    _handleCheckboxChange(state, event) {
        this.setState({[state]: event.target.checked});
    }

    _handleThumbnailPositionChange(event) {
        this.setState({thumbnailPosition: event.target.value});
    }

    _resetVideo() {
        this.setState({showVideo: {}});
    }

    _toggleShowVideo(url) {
        this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);
        this.setState({
            showVideo: this.state.showVideo
        });
    }
    onSpinProgress(progress){
        this.setState({
            spinProgress:progress
        })
    }
    _renderSpin(frames,contentId) {
        return (
            <div className='image-gallery-image'>
                <div className='spin-wrapper' style={{position: 'relative'}}>
                    <SpriteSpin frames={frames} onSpinProgress={this.onSpinProgress} contentId={contentId}></SpriteSpin>
                    {
                        this.state.spinProgress < 100 &&
                        <div>
                            <div className="progress__bar_spin percents">{this.state.spinProgress}%</div>
                            <div className="progress__bar_spin">
                                <div>
                                    <Line width={100} percent={this.state.spinProgress} strokeWidth="8" strokeColor="#48e5da" strokeLinecap={'circle'} trailColor={'#fff'} gapPosition={"bottom"}/>
                                </div>
                            </div>
                        </div>

                    }
                </div>
            </div>
        );
    }
    _renderEmptySpin() {
        return (
            <div className='image-gallery-image'>
                <img src={videoPreview}/>
                <div>
                    <div className="content-loading">
                        <div className="content-loading-text">
                            Processing...
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    _renderVideo(item) {
        return (
            <div className='image-gallery-image'>
                {
                    this.state.showVideo[item.signedUrl] ?
                        <div className='video-wrapper'>
                            <a
                                className='close-video'
                                onClick={this._toggleShowVideo.bind(this, item.signedUrl)}
                            >
                            </a>
                            <video
                                controls="controls"
                                autoPlay={true}
                                controlsList="nodownload"
                                src={item.signedUrl}
                            >
                            </video>
                        </div>
                        :
                        <a onClick={this._toggleShowVideo.bind(this, item.signedUrl)}>
                            <div className='play-button'></div>
                            <img src={item.thumbnail} alt="img"/>
                        </a>
                }
            </div>
        );
    }

    renderLeftNav(onClick, disabled) {
        return (
            <button
                className='image-gallery-custom-left-nav'
                disabled={disabled}
                onClick={onClick}/>
        )
    }

    renderRightNav(onClick, disabled) {
        return (
            <button
                className='image-gallery-custom-right-nav'
                disabled={disabled}
                onClick={onClick}/>
        )
    }

    render() {
        return (
            this.state.contents_count?<ImageGallery
                ref={i => this._imageGallery = i}
                items={this.state.images}
                lazyLoad={true}
                onSlide={this._onSlide.bind(this)}
                infinite={this.state.infinite}
                showBullets={this.state.showBullets}
                showFullscreenButton={this.state.showFullscreenButton && this.state.showGalleryFullscreenButton}
                showPlayButton={this.state.showPlayButton && this.state.showGalleryPlayButton}
                showThumbnails={this.state.showThumbnails}
                showIndex={this.state.showIndex}
                showNav={this.state.showNav}
                isRTL={this.state.isRTL}
                thumbnailPosition={this.state.thumbnailPosition}
                slideDuration={parseInt(this.state.slideDuration)}
                slideInterval={parseInt(this.state.slideInterval)}
                additionalClass="app-image-gallery"
                renderRightNav={this.renderRightNav.bind(this)}
                renderLeftNav={this.renderLeftNav.bind(this)}
                disableArrowKeys={true}
                onThumbnailError={(e) => {
                    e.target.src = videoPreview;
                }}
                disableSwipe={true}
            />:<div className={"no-image"}><img src={videoPreview}/></div>
        );
    }
}

function mapStateToProps(state) {
    const {itemDetails,userData} = state
    return {
        contents: itemDetails.details ? itemDetails.details.contents : [],
        noImages: itemDetails.details ? itemDetails.details.noImages : null,
        spinProgress:userData?userData.spinProgress:0,
        currentContent:itemDetails.props?itemDetails.props.currentContent:null
    }
}

export default connect(mapStateToProps)(ModalContentGallery);