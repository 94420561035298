import React, {Component} from "react";
import {connect} from "react-redux";
import Item from "./Item";

export class ItemsManager extends Component {
    render() {
        const {items} = this.props;
        if (items.length) {
            return (
                <>
                    {items.map((item, i) =>
                        <Item item={item}
                              key={i}
                        />
                    )}
                </>
            );
        } else {
            return (<div></div>)
        }
    }
}

function mapStateToProps(state) {
    return {items: state.items};
}


export default connect(mapStateToProps)(ItemsManager);