import React, {Component} from 'react';
import {Modal, ModalBody, ModalFooter} from 'reactstrap';
import ModalContentGallery from './ModalContentGallery';
import {connect} from "react-redux";
import ApiData from "./ApiData";
import store from "../store";
import {connectModal, hide, show} from 'redux-modal'
import ItemShare from "./ItemShare";
import {updateDetails, updateShowedComponent} from "../actions/ItemDetailsAction";
import ItemAttributes from "./ItemAttributes";
import EditSku from "./EditSku";
import AssignToSku from "./AssignToSku";
import AddAttribute from "./AddAttribute";
import AddAttributeValue from "./AddAttributeValue";
import ItemSort from "./ItemSort";
import {Scrollbar} from "react-scrollbars-custom";
import {isMobile} from 'react-device-detect';
import {generateUrlData} from '../helpers'
import {
    ADD_ATTRIBUTE,
    ADD_ATTRIBUTE_VALUE,
    EDIT_SKU,
    SHOW_ASSIGN,
    SHOW_ATTRIBUTES,
    SHOW_SHARE,
    SHOW_SORT
} from "../constants";
import {createBrowserHistory} from "history";
import {ModalHeader} from "react-bootstrap";
import {updateUserData} from "../actions/MainActions";
import ReactGA from "react-ga";

const history = createBrowserHistory();

class ItemModal extends Component {
    constructor(props, context) {
        super(props, context);
        this.handleClose = this.handleClose.bind(this)
        this.lastItemProps = {};
        this.apiData = new ApiData()
        this.state = {
            itemData: {},
            itemDataUpdated: false,
            contents_count: 1
        };
    }

    componentDidMount = async () => {
        this.setState({
            showButton: this.props.showButton,
            buttonColor: this.props.buttonColor,
            buttonTextColor: this.props.buttonTextColor,
            keyword: this.props.keyword,
            itemDataUpdated: false,
        });

        if (this.props.itemId) {
            history.push(`/${this.props.keyword?this.props.keyword:"g/"+this.props.gallery_uid}?item_id=${this.props.itemId}`);
            ReactGA.pageview(window.location.pathname + window.location.search)
        }
        let reqParams = this.getReqParams(this.props);
        if (this.props.is_owner && this.props.api_token) {
            reqParams.is_owner = this.props.is_owner;
            reqParams.forUpdate = true;
        }
        const response = await this.apiData.getDetails(reqParams)
        store.dispatch(updateDetails(response))
        this.setState({
            is_owner: this.props.is_owner,
            itemData: store.getState().itemDetails.details,
            showedComponent: store.getState().itemDetails.showedComponent,
            itemDataUpdated: true,
            currentIndex: 1,
            contents_count:response.contents.length
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const currentContentId = nextProps.contentProps?nextProps.contentProps.currentContent:null;
        let currentIndex;

        if(nextProps.itemData && nextProps.itemData.contents){
             nextProps.itemData.contents.map((item,index)=>{
                if(item.id===currentContentId) {
                    currentIndex =  index+1
                }
            });
        }
        this.setState({
            showedComponent: nextProps.showedComponent,
            itemData:nextProps.itemData,
            showButton: nextProps.showButton,
            buttonColor: nextProps.buttonColor,
            buttonTextColor: nextProps.buttonTextColor,
            currentIndex: currentIndex
        })
    }

    handleClose() {
        const params  = this.state.filters?`?${ generateUrlData(this.state.filters).join('&')}`:""
        history.push(`/${this.props.keyword?this.props.keyword:"g/"+this.props.gallery_uid}`,generateUrlData(this.state.filters));
        this.props.handleHide();
        ReactGA.pageview(window.location.pathname + window.location.search)
    }

    getReqParams(props) {
        let reqParams = {};
        if (props.keyword !== undefined) {
            reqParams.keyword = props.keyword;
        } else {
            reqParams.guid = props.gallery_uid;
        }
        reqParams.types = ['image', 'video', 'spin'];
        reqParams.sku_id = props.itemId;
        reqParams['dimensions[width]'] = 330;
        reqParams['dimensions[height]'] = 330;

        return reqParams;
    }

    toggleAttributes() {
        this.setState({
            'showAttributes': !this.state.showAttributes
        });
    }

    openEditUrl = () => {
        store.dispatch(updateShowedComponent(EDIT_SKU,{isNew:false}))
    }

    openSort = () => {
        store.dispatch(updateShowedComponent(SHOW_SORT))
    }
    openAssign = () => {
        store.dispatch(updateShowedComponent(SHOW_ASSIGN))
    }

    openShare = () => {
        store.dispatch(updateShowedComponent(SHOW_SHARE))
    }


    showContacts = () => {
        this.props.show('ContactInformationModal', {keyword: this.state.keyword, guid: this.props.gallery_uid})
    };

    deleteItem = (id) => {
        this.props.show('ConfirmRemoveModal', {id: id, type: "remove_sku", onSkuRemoved: this.onSkuRemoved})
    }

    render() {
        return (
            <Modal isOpen={true} toggle={this.handleClose} className="item-modal">
                <ModalHeader>
                    {this.state.itemData.sku && <div className="popup_title">{this.state.itemData.sku}</div>}
                    <button className="popup__close" onClick={this.handleClose}></button>
                </ModalHeader>
                <ModalBody>
                    <div className="popup_wrap">
                        <div className="row popup__data">
                            <div className="col-sm-6">
                                {
                                    this.state.itemDataUpdated && store.getState().itemDetails ?
                                    <ModalContentGallery contents_count={this.state.contents_count}/> : null
                                }
                                {
                                    isMobile && this.state.itemData.contents && this.state.itemData.contents.length?
                                    <div className={"slider__indexes"}>
                                        <span
                                            className="current__index">{this.state.currentIndex}</span> of <span>{this.state.itemData.contents.length}</span>
                                    </div> : <></>
                                }
                            </div>
                            <div className="item-details col-sm-6">
                                <div className="text">
                                    <Scrollbar style={{ width: '100%', height: 350 }}>
                                    {this.state.showedComponent === SHOW_ATTRIBUTES && <ItemAttributes/>}
                                    {this.state.showedComponent === EDIT_SKU && <EditSku/>}
                                    {this.state.showedComponent === SHOW_SORT && <ItemSort/>}
                                    {this.state.showedComponent === ADD_ATTRIBUTE_VALUE && <AddAttributeValue/>}
                                    {this.state.showedComponent === ADD_ATTRIBUTE && <AddAttribute/>}
                                    {this.state.showedComponent === SHOW_ASSIGN && <AssignToSku/>}
                                    {this.state.showedComponent === SHOW_SHARE && <ItemShare/>}
                                    </Scrollbar>
                                    </div>
                                {
                                    !isMobile && this.state.showButton &&
                                    <div className="show-contacts">
                                        <button onClick={this.showContacts}
                                                className="btn btn_white btn_border btn_small btn_shadow_none"
                                                style={
                                                    {
                                                        background: this.state.buttonColor,
                                                        color: this.state.buttonTextColor
                                                    }
                                                }
                                        >
                                            Contact info
                                        </button>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </ModalBody>
                {
                    isMobile && <ModalFooter className={"item__modal_footer"}>
                        <div className="modal_footer__price">
                            {this.state.itemData.price &&
                            <>
                            <span
                                className="currency_word">us</span>$ {this.state.itemData.price}
                            </>
                            }
                        </div>
                    {
                        this.state.showButton &&
                        <button onClick={this.showContacts}
                                className="btn btn_white btn_border btn_small btn_shadow_none btn_square"
                                style={
                                    {
                                        background: this.state.buttonColor,
                                        color: this.state.buttonTextColor
                                    }
                                }
                        >
                            Contact info
                        </button>
                    }
                </ModalFooter>
                }
            </Modal>
        );
    }
}


function mapStateToProps(state) {
    const {itemDetails, userData} = state
    return {
        showedComponent: itemDetails ? itemDetails.showedComponent : {} ,
        itemData: itemDetails ? itemDetails.details : {},
        contentProps: itemDetails? itemDetails.props:null,
        ...userData,
    }
}

export default connectModal({name: 'ItemModal', destroyOnHide: true})(connect(mapStateToProps, {show, hide})(ItemModal))