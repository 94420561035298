import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {updateItemsList} from "../actions/itemActions";
import ItemsManager from "./ItemsManager";
import store from "../store";
import ApiData from "./ApiData";
import {updateUserData} from "../actions/MainActions";

class UnassignedItems extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            page: 1,
            hasMore: true
        };
        this.apiData = new ApiData()
    }

    getReqParams() {
        var reqParams = {};
        if (this.props.keyword !== undefined) {
            reqParams.keyword = this.props.keyword;
        } else {
            reqParams.guid = this.props.gallery_uid;
        }
        reqParams.page = this.state.page;

        return reqParams;
    }

    getItems = async () => {
        var reqParams = this.getReqParams();
        reqParams.page = this.state.page + 1;
        reqParams.unassigned = 1
        const response = await this.apiData.getUnassigenedContent(reqParams);
        const {hasMore, user_id} = response;
        store.dispatch(updateItemsList(response));
        this.setState({
            items_count: store.getState().items.length,
            page: reqParams.page,
            hasMore: hasMore,
            user_id: user_id
        });

    };
    componentDidMount = async (props) => {
        this.setState({
            hasMore: true,
            page: 1
        });

        var reqParams = this.getReqParams();
        reqParams.unassigned = 1;
        const response = await this.apiData.getUnassigenedContent(reqParams);
        store.dispatch(updateItemsList(response, this.props, true, true));
        this.setState({
            items_count: store.getState().items ? store.getState().items.length : 0,
            page: reqParams.page,
            hasMore: response.hasMore,
            user_id: response.user_id
        });
    }

    componentWillUnmount() {
        store.dispatch(updateUserData({showGallery: true}))
    }

    render() {
        if (this.state.items) {
            return (
                <div className="gallery">
                    <InfiniteScroll
                        dataLength={this.state.items_count}
                        next={this.getItems}
                        hasMore={this.state.hasMore}
                        loader={<div className="row">
                            <div className="col-md-12 ta-c">
                                <div className="lds-ring">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </div>}
                        style={{'overflow': 'inherit'}}
                    >
                        <div className="gallery__list">
                            <ItemsManager/>
                        </div>
                    </InfiniteScroll>
                </div>
            );
        } else {

            return (
                <div className="gallery_h4"><h4>No items</h4></div>
            )
        }

    }
}

export default UnassignedItems;