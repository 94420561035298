import React, {Component} from 'react';
import ItemSortable from "./ItemSortable";
import Dropzone from "./Dropzone";
import store from "../store";
import {addToQueue, clearQueue, stopQueueCheck, updateQueue, removeFromQueue} from "../actions/uploadQueueActions";
import {setStatus} from "../actions/UploadStatusActions";
import {arr_diff} from "../helpers";
import {connect} from "react-redux";
import ApiData from "./ApiData";
import {updateContents, updateShowedComponent} from "../actions/ItemDetailsAction";
import {SHOW_ATTRIBUTES} from "../constants";

class ItemSort extends Component {
    constructor(props) {
        super(props);
        this.apiData = new ApiData()
        this.state = {}
    }

    componentDidMount =  ()=> {
        this.setState({
            itemData: this.props.itemData
        }, () => {
            // this.isUploading()
            // this.interval = setInterval(async () => {
            //     if (!this.props.modal.ItemModal) {
            //         return false
            //     }
            //     const status = await this.isUploading()
            //     console.log(status)
            //     if(status == 'done'){
            //         return false
            //     }
            // }, 2000);
        })

    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {itemData, uploadStatus,uploadQueue} = nextProps
        const oldQueue = this.state.uploadQueue
        this.setState({
            itemData: itemData,
            uploadingStatus: uploadStatus ? uploadStatus.status : "",
            uploadQueue: uploadQueue ? uploadQueue : []
        })
    }
    onSpinCreated = async (completed) => {
        if (completed && completed.length) {
            for (let i = 0; i < completed.length; i++) {
                let itemId = completed[i].child_id
                var data = {
                    content_id: itemId,
                    sku_id: this.state.itemData.sku_id,
                    keyword: this.state.keyword,
                    types: ['image', 'video', 'spin'],
                };
                data['dimensions[width]'] = 550;
                data['dimensions[height]'] = 550
                data['content_id'] = itemId
                const {contents} = await this.apiData.getDetails(data);
                if (contents) {
                    const item = contents[0];
                    store.dispatch(updateContents(this.state.itemData.contents.map((it, i) => it.id == item.id ? {
                        ...it,
                        thumbnail: item.thumbnail
                    } : it)))
                    store.dispatch(removeFromQueue(itemId))
                }
            }
        }
    }

    onContentStartUploading = (status) => {
        this.setState({
            uploadingStatus: status
        })
    }
    isUploading = async () => {
        let status = 'done'

        if (this.state.uploadQueue && this.state.uploadQueue.sku_id !== this.state.itemData.sku_id) {
            store.dispatch(clearQueue(this.state.itemData.sku_id))
        }
        this.setState({
            stopChecking: this.state.uploadQueue?this.state.uploadQueue.stopChecking : false
        })
        if (this.state.itemData.contents !== undefined && this.state.stopChecking !== true) {
            const response = await this.apiData.checkUploadStatus(this.state.itemData.sku_id);
            if (!response.length) {
                store.dispatch(stopQueueCheck());
            }
            let completed = [];
            let queued = []
            if (this.state.uploadQueue) {
                queued = this.state.uploadQueue.items;
            }
            if (this.state.uploadStatus && this.state.uploadStatus.fileUploading) {
                store.dispatch(setStatus(status, true));
            } else {
                if (response && response.length) {
                    if (!response.length) {
                        status = 'done'
                    } else {
                        status = 'in_progress'
                        for (const item of response) {
                            if (this.state.itemData.contents.filter(it => it.id === item.id && it.type !== 'spin')) {
                                status = 'get_sku'
                                break;
                            }
                        }
                        completed = queued.filter(arr_diff(response));
                        if (this.state.uploadStatus && completed) {
                            this.onSpinCreated(completed)
                        }
                    }
                    if (status === 'done' || response.filter(it => it.status === 'fail').length === response.length) {
                        store.dispatch(stopQueueCheck());
                    }
                    if (response) {
                        for (const item of response) {

                            if (queued && !queued.filter(it => it.id === item.id).length) {
                                store.dispatch(addToQueue(parseInt(item.id), this.state.itemData.sku_id, item.status));
                            } else {
                                store.dispatch(updateQueue(parseInt(item.id), item.status));
                            }
                        }
                    }
                } else {
                    status = 'done'
                    completed = queued.filter(arr_diff(response));
                    store.dispatch(stopQueueCheck());
                    if (store.getState().uploadStatus && completed) {
                        this.onSpinCreated(completed)

                    }
                }
            }
            store.dispatch(setStatus(status));
            this.onContentStartUploading(status)
            this.setState({
                uploadingStatus: status,
            })
        }
        return status
    }
    closeSort = () => {
        store.dispatch(updateShowedComponent(SHOW_ATTRIBUTES))
    }

    render() {
        return (
            <>
                {this.state &&
                <>

                    {
                        this.state.uploadingStatus === 'in_progress' &&
                        <div className="loader"></div>
                    }
                    <div className={"manage-media " + this.state.uploadingStatus}>
                        <ItemSortable/>
                        <Dropzone/>
                    </div>
                    <div className="sort-close">
                        <button
                            className="btn btn_white btn_border btn_small btn_shadow_none"
                            onClick={this.closeSort}>Close
                        </button>
                    </div>
                </>
                }
            </>
        )
    }
}

function mapStateToProps(state) {
    const {itemDetails, modal, uploadQueue, uploadStatus} = state
    return {
        itemData: itemDetails ? itemDetails.details : {},
        modal: modal,
        uploadQueue: uploadQueue,
        uploadStatus: uploadStatus
    }
}

export default connect(mapStateToProps)(ItemSort);