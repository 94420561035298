import React, {Component} from 'react';
import {Modal, ModalBody} from 'reactstrap';
import {connectModal, hide, show} from "redux-modal";
import apiData from "./ApiData";
import store from "../store";
import {updateUserData} from "../actions/MainActions";
import {connect} from "react-redux";

class PasswordModal extends Component {
    constructor(props, context) {
        super(props, context);
        this.handleClose = this.handleClose.bind(this);
        this.state = {
            name: null,
            password: null,
            guid: this.props.guid,
        };
        this.apiData = new apiData()

    }

    handleClose() {
        this.props.handleHide();
    }

    componentWillReceiveProps = (props) => {
        this.setState({wrongPassword: props.userData.wrongPassword})
    }

    onChangePassword = (e) => {
        this.setState({password: e.target.value})
    }
    checkPasssword = () => {
        store.dispatch(updateUserData({password: this.state.password, stopChecking: false, passwordChecked: true}))
    }

    render() {
        return (
            <Modal isOpen={true} toggle={this.handleClose} className="item-modal">
                <ModalBody>
                    <div className="popup popup-images prewiew">
                        <div className="popup__wrap">
                            <h4 className="prewiew__name">Enter password</h4>

                            <div className="row popup__data password">
                                <div className="password-popup-content col-md-4 offset-4">
                                    <div className="password-input">
                                        <input type="password" onChange={this.onChangePassword}/>
                                        <span
                                            className="error-message">{this.state.wrongPassword ? "Wrong password" : ""}</span>
                                    </div>
                                    <div>
                                        <button className="btn btn_white btn_border btn_small btn_shadow_none"
                                                onClick={this.checkPasssword}
                                                value={this.state.name}> Submit
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    const {userData} = state
    return {
        userData: userData,
    }
}

export default connectModal({name: 'PasswordModal', destroyOnHide: true})(connect(mapStateToProps, {
    show,
    hide
})(PasswordModal))