import React, {Component} from 'react'
import ApiData from "./ApiData";
import store from "../store";
import {setStatus} from "../actions/UploadStatusActions";
import {addToQueue} from "../actions/uploadQueueActions";
import {connect} from "react-redux";
import {addContent} from "../actions/ItemDetailsAction";

const ACCEPTED_TYPES = [
    'png',
    'jpg',
    'jpeg',
    'mp4',
    'mov'
]
const MAX_IMAGE_SIZE = 52428800; // 50 Mb
const MAX_VIDEO_SIZE = 524288000; // 500 Mb
class Dropzone extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hightlight: false,
            sku: this.props.sku,
            sku_id: this.props.sku_id,
            files:null
        }
        this.fileInputRef = React.createRef()

        this.openFileDialog = this.openFileDialog.bind(this)
        this.onFilesAdded = this.onFilesAdded.bind(this)
        this.onDragOver = this.onDragOver.bind(this)
        this.onDragLeave = this.onDragLeave.bind(this)
        this.onDrop = this.onDrop.bind(this)
        this.onFormSubmit = this.onFormSubmit.bind(this)
        this.onChange = this.onChange.bind(this)
        this.fileUpload = this.fileUpload.bind(this)
        this.apiData = new ApiData()
    }

    openFileDialog() {
        if (this.props.disabled) return

        this.fileInputRef.current.click()
    }

    async onFilesAdded(evt) {
        if (this.props.disabled) return
        const files = await evt.target.files
        let acceptedFiles = await this.validateFiles(files)
        this.setState({...this.state.files, files: acceptedFiles})
    }

    onDragOver(evt) {
        evt.preventDefault()

        if (this.props.disabled) return

        this.setState({hightlight: true})
    }

    onDragLeave() {
        this.setState({hightlight: false})
    }

    onDrop(event) {
        event.preventDefault()

        if (this.props.disabled) return

        const files = event.dataTransfer.files
        let acceptedfiles = this.validateFiles(event.target.files)
        this.setState({files: acceptedfiles, hightlight: false})
    }

    validateFiles = (files) => {
        let acceptedfiles = [];
        Object.entries(files).map(function ([i, file]) {
            const fileExt = file.name.split('.').pop().toLowerCase();
            if (ACCEPTED_TYPES.indexOf(fileExt) == -1) {
                alert('Wrong file type: ' + file.name);
            } else {
                if (this.isVideoFile(file) && file.size <= MAX_VIDEO_SIZE
                    || !this.isVideoFile(file) && file.size <= MAX_IMAGE_SIZE
                ) {
                    acceptedfiles.push(file)
                } else {
                    alert('File size is too big: ' + file.name);
                }
            }
        }, this)
        return acceptedfiles
    }

    onFormSubmit(e) {
        e.preventDefault() // Stop form submit
        this.fileUpload(this.state.files)
        if (this.state.files) {
            this.setState({files: null})
            store.dispatch(setStatus('in_progress', true));
        }
    }

    onChange(e) {
        let acceptedfiles = this.validateFiles(e.target.files)
        this.setState({files: acceptedfiles})
    }

    readFile(fileItem) {
        return new Promise((resolve, reject) => {
            var fileReader = new FileReader();
            fileReader.onload = async function (e) {
                resolve(fileReader.result);
            }
            fileReader.onerror = reject;
            fileReader.readAsDataURL(fileItem)

        })
    }

    isVideoFile(fileItem) {
        if (fileItem.type.search('video') !== -1) {
            return true;
        }
        return false;
    }

    getCurrentDateTime() {
        let today = new Date();
        let day = today.getDate();

        let month = today.getMonth() + 1;
        const year = today.getFullYear();
        const hour = today.getHours();
        const minutes = today.getMinutes();
        const sec = today.getSeconds();
        if (day < 10) {
            day = `0${day}`;
        }

        if (month < 10) {
            month = `0${month}`;
        }
        return today = `${year}_${month}_${day}_${hour}_${minutes}_${sec}`;
    }

    async fileUpload(files) {
        if (files) {
            for (const file of files) {
                var type = await this.isVideoFile(file) ? 'video' : 'image'
                var data = {
                    type: type,
                    fileName: this.getCurrentDateTime() + "_" + file.name,
                    fileSize: file.size
                }
                let rawLog = await this.readFile(file);
                const responseApi = await this.apiData.uploadContent(data)
                if (responseApi) {
                    const responseS3 = await this.apiData.storeS3(responseApi, file, rawLog)
                    const responseStored = await this.apiData.storeContent(responseS3, this.props.itemDetails.sku, file.size, type);
                    const contentId = responseStored.parent_id ? responseStored.parent_id : responseStored.id;
                    if (contentId) {
                        var data = {
                            content_id: contentId,
                            sku_id: this.props.itemDetails.sku_id,
                            keyword: this.props.userData.keyword,
                            types: ['image', 'video', 'spin']
                        };
                        const {contents} = await this.apiData.getDetails(data);
                        if (contents) {
                            store.dispatch(addContent(contents[0]))
                            // if (!this.isVideoFile(file)) {
                            //     store.dispatch(setStatus("done", false));
                            // } else {
                            store.dispatch(addToQueue(parseInt(contentId), this.state.sku_id, 'get_sku'));
                            store.dispatch(setStatus("get_sku", false));
                            // }
                        }
                    }
                } else {
                    store.dispatch(setStatus('done', false));
                }
            }
        }
    }

    render() {
        return (
            <div className="uplad_images">
                <form onSubmit={this.onFormSubmit}>
                    <div
                        className={`Dropzone ${this.state.hightlight ? 'Highlight' : ''}`}
                        onDragOver={this.onDragOver}
                        onDragLeave={this.onDragLeave}
                        onDrop={this.onDrop}
                        onClick={this.openFileDialog}

                        style={{cursor: this.props.disabled ? 'default' : 'pointer'}}
                    >
                        <input
                            ref={this.fileInputRef}
                            className="FileInput"
                            type="file"
                            multiple
                            onChange={this.onFilesAdded}
                            accept=".png,.jpg,.jpeg,.mp4,.mov"
                        />
                        <span>Upload Files</span>
                        {
                            this.state.files ?
                                <span>Uploaded {this.state.files.length} file{this.state.files.length > 1 ? "s" : ""}</span>
                                :
                                ""

                        }
                        <span>JPG, JPEG, PNG, MP4, MOV</span>
                        <span>(press submit to upload files)</span>
                    </div>
                    <div className="show-contacts">
                        <input className="btn btn_white btn_border btn_small btn_shadow_none" type="submit"/>
                    </div>
                </form>
            </div>

        )
    }
}

function mapStateToProps(state) {
    const {userData, itemDetails} = state
    return {userData: userData, itemDetails: itemDetails ? itemDetails.details : {}}
}

export default connect(mapStateToProps)(Dropzone);