import React, {Component} from 'react';
import ModalContentGallery from './ModalContentGallery';
import ApiData from "./ApiData";
import {connect} from "react-redux";
import {hide, show} from "redux-modal";
import PasswordModal from "./PasswordModal";
import store from "../store";
import {updateData} from "../actions/ItemDetailsAction";
import {updateUserData} from "../actions/MainActions";
import ItemAttributes from "./ItemAttributes";
import ContactInformationModal from "./ContactInformationModal";
import ReactGA from "react-ga";

class SharedItem extends Component {
    constructor(props) {
        super(props);

        this.onPasswordChecked = this.onPasswordChecked.bind(this);

        this.state = {
            itemData: null,
            showAttributes: true,
            isContent: this.props.route.isContent?1:0,
            isRapnet: this.props.route.isRapnet?1:0,
            stopChecking: false,
            contentLoading:true,
        };
        this.apiData = new ApiData()
        this.message = null;

    }

    componentDidMount = async () => {
        let reqParams={}
        reqParams.guid =this.props.params.item_uid;
        reqParams.fromSharedLink = true;
        reqParams.isContent = this.state.isContent
        const {button_color, button_text_color, showButton} = await this.apiData.getContactButton(reqParams)
        if (showButton) {
            this.setState({
                buttonColor: button_color,
                buttonTextColor: button_text_color,
                buttonGetted: true,
                showButton: true
            });
        } else {
            this.setState({
                showButton: false
            })
        }
        ReactGA.pageview(window.location.pathname + window.location.search)
        this.getItems()
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            itemData: nextProps.itemDetails,
            stopChecking: nextProps.stopChecking,
            password: nextProps.password,
            passwordChecked: nextProps.passwordChecked,
        }, () => {
            this.getItems()
        })
    }

    isSharedLink() {
        return this.state.gallery_uid !== undefined;
    }

    onPasswordChecked(password){
       this.getItems(password)
    }

    // componentWillMount() {
    //     this.getItems()
    // }

    getItems = async () => {
        if (!this.state.stopChecking) {
            const response = await this.apiData.shared({
                types: ['image', 'video', 'spin'],
                uid: this.props.params.item_uid,
                'dimensions[width]': 550,
                'dimensions[height]': 550,
                is_content: this.state.isContent,
                is_rapnet: this.state.isRapnet,
                password: this.state.password
            });
            if(!response.success) {
                this.setState({
                    message:response.message,
                })
            } else {
                store.dispatch(updateUserData({stopChecking: true}))
                store.dispatch(updateData(response))
                this.setState({passwordCorrect: true,contents_count:response.contents.length})
                this.props.hide('PasswordModal')
            }
            if (response.password) {
                this.setState({requestPassword: true})
                this.props.show('PasswordModal');
                store.dispatch(updateUserData({
                    wrongPassword: this.state.passwordChecked ? true : false,
                    stopChecking: true
                }))
            }
            this.setState({
                contentLoading:false
            })
        }
    }


    renderInfo() {
        return (
            <ItemAttributes/>
        );
    }

    showContacts = () => {
        this.props.show('ContactInformationModal', {keyword: this.state.keyword, guid: this.props.params.item_uid,isContent:this.state.isContent})
    };

    render() {
        return (
                <div className="container shared__item">
                    {

                        this.state.itemData && this.state.passwordCorrect ?
                            <>
                                <ModalContentGallery contents_count={this.state.contents_count} showThumbnails={false}/>
                            </>
                            :
                            <div className="error__message"><h4>{this.state.message}</h4></div>
                    }
                    {
                        this.state.contentLoading &&
                            <div className="error__message">
                                <h4>Loading</h4>
                            </div>
                    }
                </div>
        );
    }
}

function mapStateToProps(state) {
    const {itemDetails, userData} = state
    return {
        itemDetails: itemDetails ? itemDetails.details : null,
        ...userData
    }
}

export default connect(mapStateToProps, {show, hide})(SharedItem)