import React, {Component} from 'react';
import CreatableSelect from 'react-select/creatable';
import ApiData from "./ApiData";
import {colourStyles, EDIT_SKU, SHOW_ASSIGN, SHOW_ATTRIBUTES} from "../constants"
import {connect} from "react-redux";
import store from "../store";
import {addItem, removeItem, updateThumbnail} from "../actions/itemActions";
import {removeContent, removeDetails, updateContents, updateShowedComponent} from "../actions/ItemDetailsAction";
import {hide} from 'redux-modal'

class AssignToSku extends Component {
    constructor(props, context) {
        super(props, context);
        this.apiData = new ApiData();
        this.onChangeSelect = this.onChangeSelect.bind(this);
        this.state = {
            skuList: [],
            sku_id: null,
            showedComponent: SHOW_ASSIGN
        }
    }


    componentDidMount = () => {
        const {attributes} = store.getState().userData;
        this.setState({
            skuList: attributes.sku.data,
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            showedComponent: nextProps.showedComponent
        })
    }

    onChangeSelect = async (value) => {
        if (value && (value['__isNew__'] || value.label === "Add new sku")) {
            store.dispatch(updateShowedComponent(EDIT_SKU, {oldData: this.props.itemDetails, isNew: true}))
            store.dispatch(removeDetails())
        }
        this.setState({
            new_sku_id: value.value
        })
    }
    updateSku = async () => {
        const {success,sku,sku_id} = await this.apiData.assginToSku(this.props.currentContent, this.state.new_sku_id)
        if (success) {
            if (!this.props.userData.showGallery) {
                store.dispatch(removeItem(this.props.currentContent))
                this.props.hide('UnassignedModal')
            } else {
                const oldContent = this.props.contents.filter(it => it.id === this.props.currentContent);
                store.dispatch(removeContent(this.props.currentContent))
                if (this.props.contents.length) {
                    store.dispatch(updateThumbnail(this.props.contents[0].thumbnail, this.props.sku_id));
                } else {
                    store.dispatch(removeItem(this.props.sku_id))
                }
                if (oldContent) {
                    store.dispatch(updateThumbnail(oldContent[0].thumbnail, this.state.new_sku_id));
                }
            }
        }
    }
    handleClose= () =>{
        store.dispatch(updateShowedComponent(SHOW_ATTRIBUTES))
    }
    render() {
        return (
            <>
                {
                    <div className="assign-to-sku">
                        <CreatableSelect
                            isClearable={true}
                            ref="SKU"
                            onChange={(val) => {
                                this.onChangeSelect(val)
                            }}
                            getOptionLabel={option => option.label ? option.label : "Not found. Create new value?"}
                            getOptionValue={option => option.value}
                            options={this.state.skuList ? [
                                {
                                    value: "",
                                    label: "-"
                                },
                                {
                                    value: "",
                                    label: "Add new sku"
                                }
                            ].concat(this.state.skuList) : []}

                            placeholder="SKU"
                            styles={colourStyles}
                            name="SKU"
                        />
                        <div className="show-contacts">
                            <button
                                className="btn btn_white btn_border btn_small btn_shadow_none"
                                onClick={this.updateSku}>Update
                            </button>
                        </div>
                        <div className="show-contacts">
                            <button
                                className="btn btn_white btn_border btn_small btn_shadow_none"
                                onClick={this.handleClose}>Close
                            </button>
                        </div>
                    </div>
                }
            </>
        )
    }
}

const mapStateToProps = state => {
    const {userData, itemDetails, items} = state
    return {
        userData: userData,
        currentContent: itemDetails.props.currentContent,
        itemDetails: itemDetails.details,
        sku_id: itemDetails.details.sku_id,
        contents: itemDetails.details.contents,
        items: items,
        showedComponent: itemDetails ? itemDetails.showedComponent : {},
    }
}
export default connect(mapStateToProps, {hide})(AssignToSku);