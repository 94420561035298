import React, {Component} from 'react';
import {Modal, ModalBody} from 'reactstrap';
import ModalContentGallery from './ModalContentGallery';
import {connect} from "react-redux";
import ApiData from "./ApiData";
import store from "../store";
import {connectModal} from 'redux-modal'
import {show} from 'redux-modal'
import AssignToSku from "./AssignToSku";
import {updateDetails} from "../actions/ItemDetailsAction";

class UnassignedModal extends Component {
    constructor(props, context) {
        super(props, context);
        this.handleClose = this.handleClose.bind(this);

        this.lastItemProps = {};
        this.state = {
            itemData: [],
            show: false,
            showAttributes: true,
            editAttributes: false,
            showSort: false,
            structure: [],
            selected: [],
            stopChecking: false,
        };
        this.apiData = new ApiData()
    }

    handleClose() {
        this.props.handleHide();
    }

    getReqParams(props) {
        var reqParams = {};
        if (props.keyword !== undefined) {
            reqParams.keyword = props.keyword;
        } else {
            reqParams.guid = props.gallery_uid;
        }
        reqParams.types = ['image', 'video', 'spin'];
        reqParams.sku_id = props.itemId;
        reqParams['dimensions[width]'] = 550;
        reqParams['dimensions[height]'] = 550;

        return reqParams;
    }

    handleShow = async (props) => {
        this.setState({
            showButton: props.showButton,
            buttonColor: props.buttonColor,
            buttonTextColor: props.buttonTextColor,
            keyword: props.keyword,
        });
        var reqParams = {
            content_id: props.itemId,
            keyword: this.state.keyword,
            types: ['image', 'video', 'spin'],
            ['dimensions[width]']: 550,
            ['dimensions[height]']: 550,
        };
        const response = await this.apiData.getDetails(reqParams)
        store.dispatch(updateDetails(response))
        this.setState({
            is_owner: this.props.is_owner,
            itemData: store.getState().itemDetails.details,
        });
    }

    componentDidMount() {
        var props = this.props;
        this.handleShow(props)
    }

    showContacts = () => {
        this.props.show('ContactInformationModal', {keyword: this.state.keyword})
    };
    deleteItem = (id) => {
        this.props.show('ConfirmRemoveModal', {id: id, type: "remove_sku", onSkuRemoved: this.onSkuRemoved})
    }

    openAssign = () => {
        this.setState({
            'showAssign': true
        })
    }

    render() {
        return (
            <Modal isOpen={true} toggle={this.handleClose} className="item-modal">
                <ModalBody>
                    <button className="popup__close" onClick={this.handleClose}/>
                    <div className="popup popup-images prewiew">
                        <div className="popup__wrap">
                            <div className="row popup__data">
                                <div className="col-sm-6">
                                    {store.getState().itemDetails ? <ModalContentGallery/> : null}
                                </div>
                                <div className="item-details col-sm-6">
                                    <div>
                                    </div>
                                    {
                                        this.state.showAssign ?
                                            <div className="text">
                                                <AssignToSku/>
                                            </div>
                                            :
                                            <>
                                                <div className="show-contacts">
                                                    <button onClick={() => {
                                                        this.openAssign()
                                                    }}
                                                            className="btn btn_white btn_border btn_small btn_shadow_none"
                                                    >
                                                        Assign to sku
                                                    </button>
                                                </div>
                                                <div className="show-contacts">
                                                    <button onClick={this.showContacts}
                                                            className="btn btn_white btn_border btn_small btn_shadow_none"
                                                            style={
                                                                {
                                                                    background: this.state.buttonColor,
                                                                    color: this.state.buttonTextColor
                                                                }
                                                            }
                                                    >
                                                        Contact info
                                                    </button>
                                                </div>
                                            </>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    const {itemDetails, userData} = state
    return {
        showedComponent: itemDetails ? itemDetails.showedComponent : {},
        ...userData,
    }
}

export default connectModal({
    name: 'UnassignedModal',
    destroyOnHide: true
})(connect(mapStateToProps, {show})(UnassignedModal))