import React from 'react';
import {SortableElement} from 'react-sortable-hoc';
import {connect} from "react-redux";
import {show} from "redux-modal";

const SortableImageContainer = SortableElement((props) => {
    return (
        <div className={props.status + " sortable-img"}>


            <div className={"img-container col-sd-2 " + props.status} style={{zIndex: 10000}}>

                {/*{*/}
                {/*    props.status !== 'done' ?*/}
                {/*        <>*/}
                {/*        <div className={"sortable-container sort-img-overlay"}>*/}
                {/*        </div>*/}
                {/*        <div className="item-loader"></div>*/}
                {/*            {*/}
                {/*                props.status==='fail'?*/}
                {/*                    <span className={props.status}>Fail</span>*/}
                {/*                    :*/}
                {/*                    ""*/}
                {/*            }*/}
                {/*        </>*/}
                {/*        :*/}
                {/*        ""*/}
                {/*}*/}

                <div className="sortable-container">
                    <div className="item_actions">
                         <span className="item_type">{props.type === "spin" ? "Video360" : props.type.charAt(0).toUpperCase() + props.type.slice(1)}</span>
                        {
                            props.download_url ?
                                <a className="catalog__item-btn download-item" target="_blank"
                                   href={props.download_url} download rel="noopener noreferrer">
                                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path fill="#A29EA1" fillRule="nonzero"
                                              d="M 12 1.5 C 17.799 1.5 22.5 6.201 22.5 12 C 22.5 17.799 17.799 22.5 12 22.5 C 6.201 22.5 1.5 17.799 1.5 12 C 1.5 6.201 6.201 1.5 12 1.5 Z M 12 2.5 C 4.687 2.5 0.116 10.417 3.773 16.75 C 5.47 19.689 8.606 21.5 12 21.5 C 19.313 21.5 23.884 13.583 20.227 7.25 C 18.53 4.311 15.394 2.5 12 2.5 Z"/>
                                        <path style={{fill: "rgb(216, 216, 216)", stroke: "rgb(162, 158, 161)"}}
                                              d="M 12.902 -0.169 L 9.132 11.21"
                                              transform="matrix(0.951423, -0.307887, 0.307887, 0.951423, -0.153242, 10.069734)"/>
                                        <path style={{fill: "rgb(216, 216, 216)", stroke: "rgb(162, 158, 161)"}}
                                              d="M 7.334 15.709 L 11.938 18.719 L 16.5 15.792"/>
                                        <path style={{fill: "rgb(216, 216, 216)", stroke: "rgb(0, 0, 0)"}}/>
                                        <path style={{fill: "rgb(216, 216, 216)", stroke: "rgb(0, 0, 0)"}}
                                              d="M 12.25 17.167"/>
                                    </svg>
                                </a>
                                :
                                ""
                        }
                        <a className="catalog__item-btn delete-item" href="#">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 onClick={() => {
                                     props.show('ConfirmRemoveModal', {
                                         id: props.id,
                                         type: "remove_content"
                                     })
                                 }}
                            >
                                <path fill="#A29EA1" fillRule="nonzero"
                                      d="M12 1.5c5.799 0 10.5 4.701 10.5 10.5S17.799 22.5 12 22.5 1.5 17.799 1.5 12 6.201 1.5 12 1.5zm0 1a9.5 9.5 0 1 0 0 19 9.5 9.5 0 0 0 0-19zm4.61 5.63v1l-.692-.001-.896 8.371h-6.05l-.898-8.371H7.3v-1h9.31zm-1.697.999H9.08l.79 7.371h4.253l.79-7.371zm-3.39 1.003v5.12h-1v-5.12h1zm1.862 0v5.12h-1v-5.12h1zm.431-3.4v1h-3.723v-1h3.723z"/>
                            </svg>
                        </a>
                    </div>
                    <div className="picture">
                        <img src={props.image ? props.image : require(`./../images/preview.png`)} width="100" height="100"
                             onError={(e) => {
                                 e.target.src = require(`./../images/preview.png`);
                             }}/>
                        <span></span>
                    </div>
                    <div className="picture-name">
                        <p>{props.name}</p>
                    </div>
                </div>
            </div>
        </div>
    )
})
export default connect(null, {show})(SortableImageContainer);