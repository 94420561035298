import React, {Component} from 'react';
import {connect} from "react-redux";
import store from "../store";
import {updateShowedComponent, updateStructure} from "../actions/ItemDetailsAction";
import ApiData from "./ApiData";
import {EDIT_SKU} from "../constants";

class AddAttribute extends Component {
    constructor(props) {
        super(props);
        this.apiData = new ApiData()
        this.state = {
            structure: {},
            selected: {},
        }
    }

    componentDidMount() {
        this.setState({
            notValid: false,
            newAttribute: null
        })
    }

    onChangeAttribute = (e) => {
        this.setState({
            'newAttribute': e.target.value
        })
    };
    addAttribute = async () => {
        var structure = this.props.structure;
        const formData = {
            'UserCatalogItemAttributes[name]': this.state.newAttribute,
            'UserCatalogItemAttributes[is_multiple_choice]': this.state.is_multiple_choice,
            'UserCatalogItemAttributes[is_required]': this.state.is_required,
        }
        const {success, data, errors} = await this.apiData.createAttribute(formData)
        if (success) {
            structure[data.id] = {
                id: data['id'] ? data['id'] : null,
                label: data['name'] ? data['name'] : null,
                code: data['code'] ? data['code'] : null,
                values: [],
                type: 'text',
                is_visible: data.is_visible,
                is_multiple_choice: this.state.is_multiple_choice,
                is_required: this.state.is_required
            }
            store.dispatch(updateStructure(structure))
            this.handleClose()
        } else {
            if (errors['usercatalogitemattributes-name'] !== undefined) {
                this.setState({
                    error_message: errors['usercatalogitemattributes-name'][0],
                    not_valid: true
                })
            }
        }
    }
    onChangeInput = (e, name) => {
        this.setState({[name]: e.target.checked ? 1 : 0})
    }

    handleClose() {
        store.dispatch(updateShowedComponent(EDIT_SKU))
    }

    render() {
        return (
            <>
                <div
                    className={this.state.notValid ? 'form-group not_valid' : "form-group"}>
                    <input className="form-control" required type="text"
                           value={this.state.newAttribute} name="newAttribute"
                           onChange={this.onChangeAttribute}
                           placeholder="Attribute name"
                           maxLength={255}/>
                    <span className="error-message">{this.state.error_message}</span>
                </div>
                <div className="show-contacts">
                    <label htmlFor="is_required">is_required attribute</label>
                    <input id="is_required" type="checkbox" onChange={(e) => this.onChangeInput(e, 'is_required')}/>
                </div>
                <div className="show-contacts">
                    <label htmlFor="is_multiple_choice">Multiple choice</label>
                    <input id="is_multiple_choice" type="checkbox" onChange={(e) => this.onChangeInput(e, 'is_multiple_choice')}/>
                </div>
                <div className="form-group">
                    <div className="col-sd-6 update-submit">
                        <button
                            className="btn btn_white btn_border btn_small btn_shadow_none"
                            onClick={() => {
                                this.addAttribute()
                            }}>Submit
                        </button>
                    </div>
                    <div className="col-sd-6 update-submit">
                        <button
                            className="btn btn_white btn_border btn_small btn_shadow_none"
                            onClick={() => {
                                this.handleClose()
                            }}>Close
                        </button>
                    </div>

                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    const {itemDetails} = state
    return {structure: itemDetails ? itemDetails.details : {}}
}

export default connect(mapStateToProps)(AddAttribute);