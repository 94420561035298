import {SET_UPLOAD_STATUS} from "../actions/actionTypes";

const defaultState = null;

export default (state = defaultState, action = {}) => {
    switch (action.type) {
        case SET_UPLOAD_STATUS: {
            return {
                status: action.payload.status,
                fileUploading: action.payload.fileUploading,
            }
        }

        default:
            return state;
    }

};