import {UPDATE_ITEMS_LIST, UPDATE_ITEM, REMOVE_ITEM, ADD_ITEM, CLEAR_ITEMS} from "./actionTypes";
export function updateItemsList(itemData, clearOld = false,unassigned=false) {
    return {
        type: UPDATE_ITEMS_LIST,
        payload: {itemData: itemData, clearOld: clearOld,unassigned:unassigned}
    };
}
export function addItem(item,oldSku) {
    return {
        type: ADD_ITEM,
        payload: {item: item,oldSku}
    };
}
export function removeItem(itemId) {
    return {
        type: REMOVE_ITEM,
        payload: {itemId: itemId}
    };
}
export function updateThumbnail(image,sku_id) {
    return {
        type: UPDATE_ITEM,
        payload: {image:image,sku_id:sku_id}
    };
}
export function clearItems() {
    return {
        type: CLEAR_ITEMS,
    };
}