import React, {Component} from 'react';
import axios from 'axios';
import Gallery from './Gallery';
import {components} from 'react-select';
import {createBrowserHistory} from 'history';
import {NotificationContainer} from "react-notifications";
import 'react-notifications/lib/notifications.css';
import {connect} from "react-redux";
import ApiData from "./ApiData";
import {hide, show} from 'redux-modal'
import ContactInformationModal from "./ContactInformationModal";
import ItemModal from "./ItemModal";
import ShareGalleryModal from "./ShareGalleryModal";
import ConfirmRemoveModal from "./ConfirmRemoveModal"
import UnassignedItems from "./UnassignedItems"
import UnassignedModal from "./UnassignedModal";
import store from "../store";
import PasswordModal from "./PasswordModal";
import {updateUserData} from "../actions/MainActions";
import FiltersModal from "./FiltersModal";
import {generateUrlData, halfSplice, getFiltersFromUrl} from "../helpers";
import {updateItemsList} from "../actions/itemActions";
import contactUs from "../images/contact_us.png"
import ReactGA from 'react-ga';

const history = createBrowserHistory();
const queryString = require('query-string');

class GalleryWrapper extends Component {
    constructor(props) {
        super(props);
        this.onChangeFilters = this.onChangeFilters.bind(this);
        this.handleSkuChange = this.handleSkuChange.bind(this);
        this.showSearchModal = this.showSearchModal.bind(this)
        this.state = {
            showSearch: false
        }
        let {reqParams,filters} = getFiltersFromUrl(queryString.parse(props.location.search, {ignoreQueryPrefix: true}));

        let item_id = null;
        if (props.location.query['item_id']) {
            const reqParams = {
                type: ['image', 'video', 'spin'],
                itemId: props.location.query['item_id'],
                keyword: props.params.gallery,
                ['dimensions[width]']: 550,
                ['dimensions[height]']: 550
            }
            this.props.show('ItemModal', reqParams)
            item_id = props.location.query['item_id']
        }
        store.dispatch(updateUserData({
            logo: '',
            attributes: {},
            sku_id: props.location.query.sku_id ? props.location.query.sku_id * 1 : [],
            item_id: item_id,
            is_owner: props.location.query.is_owner,
            api_token: props.location.query.api_token,
            gallery_uid: props.params.gallery_uid,
            filter_enabled: props.location.query.f !== undefined,
            showHeader: props.location.query.header !== "0",
            showGallery: true,
            page: 1,
            clearOld: false,
            init: true,
            getNewItems: true,
            reqParams: reqParams,
            filters: filters
        }))
        this.state = {
            ...this.state,
            ...store.getState().userData
        }
        this.apiData = new ApiData();
        if (props.location.query.api_token) {
            axios.defaults.headers.common['x-api-token'] = `Bearer ${props.location.query.api_token}`;
            axios.defaults.headers.common['Accept'] = 'application/json';
        }
        this.checkboxOptions = (props) => {
            const {data, selectProps} = props;
            return (
                <div className="options-multi">
                    <div className="sub-checkbox">
                        <input type="checkbox" value={data.value} onChange={(val) => {
                            this.onChangeFilters(selectProps.name, data.value)
                        }}
                               checked={this.state[selectProps.name] && this.state[selectProps.name].indexOf(data.value) !== -1 ? true : false}/>
                    </div>
                    <div className="sub-text">{data.label} </div>
                </div>

            );
        };
        this.ValueContainer = ({children, getValue, ...props}) => {
            var length = getValue().length;
            const placeholder = !props.selectProps.menuIsOpen ? props.selectProps.placeholder : ""
            return (
                <components.ValueContainer {...props}>
                    <div className="custom-placeholder">
                        {!props.selectProps.menuIsOpen && length ?
                            `${length} selected` : placeholder}
                        {React.cloneElement(children[1])}
                    </div>
                </components.ValueContainer>
            );
        };
    }

    componentDidMount = async () => {
        ReactGA.pageview(window.location.pathname + window.location.search)
        var reqParams = {};
        let data = {};
        if (!this.isSharedLink()) {
            reqParams.keyword = this.props.params.gallery;
        } else {
            reqParams.guid = this.state.gallery_uid;
        }
        if (this.state.showHeader) {
            const logo = await this.apiData.getLogo(reqParams);
            data = {
                ...data,
                logo: logo,
                keyword: this.props.params.gallery,
                guid: this.state.gallery_uid

            }
        }
        const {button_color, button_text_color, showButton} = await this.apiData.getContactButton(reqParams)
        if (showButton) {
            data = {
                ...data,
                buttonColor: button_color,
                buttonTextColor: button_text_color,
                buttonGetted: true,
                showButton: true
            };
        } else {
            data = {
                ...data,
                showButton: false
            }
        }
        store.dispatch(updateUserData(data));
    }

    componentWillReceiveProps(props) {
        this.setState({
            ...props.userData
        })
    }


    isSharedLink() {
        return this.state.gallery_uid !== undefined;
    }

    isFilterEnabled() {
        return this.state.filter_enabled;
    }

    onChangeFilters(name, value) {
        let values = this.state[name] ? this.state[name] : []
        if (values.indexOf(value) !== -1) {
            values = values.filter(it => it !== value)
        } else {
            values.push(value)
        }
        store.dispatch(updateUserData({[name]: values, clearOld: true, page: 1, stopChecking: false}))
    }

    shareFilters = () => {
        this.props.show('ShareGalleryModal', this.state)
    }

    handleOnChange(name, values) {
        if (Array.isArray(values)) {
            if (!values) {
                values = [];
            } else {
                values = values.map(it => it.value)
            }
        }
        store.dispatch(updateUserData({[name]: values, clearOld: true, page: 1, stopChecking: false}))
    }

    showFilters = () => {
        this.props.show('FiltersModal', {
            keyword: this.state.keyword,
            gallery_uid: this.state.gallery_uid,
            filtersEnabled: this.isFilterEnabled()
        })
    }
    showSearchModal = () => {
        this.setState({
            showSearch: this.state.showSearch ? false : true
        })
    }
    clearSearch = async () => {
        this.setState({
            filters: {...this.state.filters,sku:""}
        })
        let reqParams = {}
        if (this.state.keyword != undefined) {
            reqParams.keyword = this.state.keyword;
        } else {
            reqParams.guid = this.state.gallery_uid;
        }
        reqParams.new_catalog = 1;
        const response = await this.apiData.getItems(reqParams);
        store.dispatch(updateItemsList(response, true));
        store.dispatch(updateUserData({filters: this.state.filters}));

        history.push(`/${this.state.keyword?this.state.keyword:"g/"+this.state.gallery_uid}`,generateUrlData(reqParams));
    }
    getFilters = async () => {
        let reqParams = {}
        if (this.props.keyword) {
            reqParams.keyword = this.props.keyword
        }
        if (this.props.gallery_uid) {
            reqParams.gallery_uid = this.props.gallery_uid
        }
        reqParams.filtersEnabled = this.isFilterEnabled()
        const {attributeFilters, mediaFilters, visibleAttrCount, price} = await this.apiData.getFilters(reqParams);
        this.setState({
            attributeFilters: halfSplice(Object.entries(attributeFilters)),
            filterAttributes: attributeFilters,
            mediaFilters: mediaFilters,
            visibleAttrCount: visibleAttrCount,
            priceAttr: price
        })
    };
    handleKeyPress = async (event) => {
        if (event.key === 'Enter') {
            this.setState({showSearch: false})
            let reqParams = {}
            if (this.state.keyword != undefined) {
                reqParams.keyword = this.state.keyword;
            } else {
                reqParams.guid = this.state.gallery_uid;
            }
            reqParams.new_catalog = 1;
            reqParams['UserCatalogItemSearch[attr][sku]'] = this.state.filters.sku ? this.state.filters.sku.value[0] : ""
            let search =[]
            Object.entries(reqParams).map((v) => {
                if (v[0] != 'gallery_uid' && v[0] !== 'new_catalog' && v[0] !== 'keyword' && v[1].length) {
                    search += v[0] + "=" + v[1] + "&"
                }
            })
            const response = await this.apiData.getItems(reqParams);
            store.dispatch(updateItemsList(response, true));
            store.dispatch(updateUserData({hasMore:response.hasMore}));

            if (this.state.filters.sku && this.state.filters.sku.value.length) {
                history.push({
                    pathname: window.location.pathname,
                    search:search
                });
            }
        }
    }

    handleSkuChange(event) {
        let filters = this.state.filters;
        filters.sku = {value: [event.target.value]}
        this.setState({
            filters: filters
        })
    }

    render() {
        return (
            <div className="wrap">
                <header className="header">
                    <div className="header__wrap header__wrap_filters">

                        {
                            !this.isFilterEnabled() ?
                                <div className="header__item row">
                                    <div className="col-xl-1 col-lg-1">
                                        {
                                            this.state.showHeader ?
                                                this.state.logo === '' ?
                                                    <div className="header__item">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="40"
                                                             viewBox="0 0 35 40">
                                                            <path fill="#00CAC4" fillRule="evenodd"
                                                                  d="M17.861.05l16.96 9.908c.11.065.179.184.179.312V29.73a.362.362 0 0 1-.18.312L17.86 39.95a.362.362 0 0 1-.36.003l-5.593-3.15v-6.865l5.596 3.151c.11.063.245.063.355 0l11.184-6.3a.362.362 0 0 0 .185-.315v-12.59a.362.362 0 0 0-.177-.312L17.863 6.919a.362.362 0 0 0-.366-.002L5.954 13.572a.362.362 0 0 0-.18.313V33.19L0 29.937V10.273c0-.13.07-.25.182-.315L17.5.05a.362.362 0 0 1 .362.002zm-1.083 13.445l6.856 6.866-6.856 6.865-2.886-2.168v-9.033l2.886-2.53z"/>
                                                        </svg>
                                                    </div>
                                                    :
                                                    <img alt="logo" style={{maxWidth: 56}} src={this.state.logo}/>
                                                : ''
                                        }
                                    </div>
                                    {
                                        this.state.showGallery ?
                                            <div className=" col-xl-7 col-lg-7 ">

                                                <div className="filter__menu">
                                                    <div className="search_sku_block">
                                                        <div className="search__input_sku">
                                                            <input ref="sku"
                                                                   value={this.state.filters.sku ? this.state.filters.sku.value[0] : ""}
                                                                   onChange={this.handleSkuChange}
                                                                   onKeyPress={this.handleKeyPress}
                                                                   type="text"
                                                                   className="search__sku"
                                                            />
                                                            {this.state.filters.sku && <div className="clear__input" aria-hidden="true" id="clear-sku-field"
                                                                                            onClick={this.clearSearch}></div>}
                                                        </div>
                                                        <a onClick={this.showFilters} className="filters_btn">
                                                            <svg width="15" height="12" viewBox="0 0 15 12" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <line y1="1.25" x2="15" y2="1.25" stroke="#A29EA1"
                                                                      strokeWidth="1.5"></line>
                                                                <line x1="2" y1="6.25" x2="13" y2="6.25"
                                                                      stroke="#A29EA1" strokeWidth="1.5"></line>
                                                                <line x1="4" y1="11.25" x2="11" y2="11.25"
                                                                      stroke="#A29EA1" strokeWidth="1.5"></line>
                                                            </svg>
                                                            Filters
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="catalog__filters col-xl-5 col-lg-5"></div>
                                    }
                                    <div className=" col-xl-4 col-lg-4 wrap_buttons">
                                        <div className="wrap">
                                            {
                                                this.state.is_owner && this.state.showGallery ?
                                                    <div className="buttons col-md-4">
                                                        <button onClick={this.shareFilters}
                                                                className="btn btn_grey btn_border btn_small btn_shadow_none">Share
                                                        </button>
                                                    </div>

                                                    :
                                                    <div className="buttons col-md-4"></div>
                                            }
                                            {
                                                this.state.showButton ?
                                                    <div className="buttons col-md-12">
                                                        <button onClick={() => {
                                                            this.props.show('ContactInformationModal', {
                                                                keyword: this.props.params.gallery,
                                                                guid: this.state.gallery_uid
                                                            })
                                                        }}
                                                                className="btn btn_grey btn_border btn_small btn_shadow_none"
                                                                style={
                                                                    {
                                                                        background: this.state.buttonColor,
                                                                        color: this.state.buttonTextColor
                                                                    }
                                                                }
                                                        >
                                                            Contact info
                                                        </button>
                                                    </div>
                                                    :
                                                    <div className="buttons col-md-2"></div>
                                            }
                                            {
                                                this.state.is_owner ?
                                                    <div className="buttons col-md-4">
                                                        <button onClick={() => {
                                                            store.dispatch(updateUserData({
                                                                showGallery: this.state.showGallery ? false : true,
                                                                clearOld: true,
                                                                page: 1
                                                            }));
                                                        }}
                                                                className="btn btn_grey btn_border btn_small btn_shadow_none"
                                                        >
                                                            {this.state.showGallery ? 'Unassigned' : 'Assigned'}
                                                        </button>
                                                    </div>
                                                    :
                                                    <div className="buttons col-md-4"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                : ''
                        }
                    </div>
                    <div className="header__wrap_mobile">

                        {
                            !this.isFilterEnabled() ?
                                <div className="header__item">
                                    <div className="header__logo">
                                        {
                                            this.state.showHeader ?
                                                this.state.logo === '' ?
                                                    <div className="header__item">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="40"
                                                             viewBox="0 0 35 40">
                                                            <path fill="#00CAC4" fillRule="evenodd"
                                                                  d="M17.861.05l16.96 9.908c.11.065.179.184.179.312V29.73a.362.362 0 0 1-.18.312L17.86 39.95a.362.362 0 0 1-.36.003l-5.593-3.15v-6.865l5.596 3.151c.11.063.245.063.355 0l11.184-6.3a.362.362 0 0 0 .185-.315v-12.59a.362.362 0 0 0-.177-.312L17.863 6.919a.362.362 0 0 0-.366-.002L5.954 13.572a.362.362 0 0 0-.18.313V33.19L0 29.937V10.273c0-.13.07-.25.182-.315L17.5.05a.362.362 0 0 1 .362.002zm-1.083 13.445l6.856 6.866-6.856 6.865-2.886-2.168v-9.033l2.886-2.53z"/>
                                                        </svg>
                                                    </div>
                                                    :
                                                    <img alt="logo" style={{maxWidth: 56}} src={this.state.logo}/>
                                                : ''
                                        }
                                        <svg width="117" height="19" viewBox="0 0 117 19" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M9.19363 3.01896C10.0404 3.39596 10.6828 3.93246 11.1354 4.62846C11.588 5.32446 11.8216 6.15096 11.8216 7.09346C11.8216 8.03596 11.588 8.84796 11.1354 9.54396C10.6828 10.24 10.0404 10.7765 9.19363 11.1535C8.34683 11.5305 7.36863 11.7045 6.22983 11.7045H3.63103V15.228H0.652634V2.45346H6.22983C7.36863 2.45346 8.36143 2.64196 9.19363 3.01896ZM8.09863 8.73196C8.56583 8.35496 8.79943 7.80396 8.79943 7.09346C8.79943 6.36846 8.56583 5.81746 8.09863 5.44046C7.63143 5.06346 6.94523 4.86046 6.05463 4.86046H3.63103V9.29746H6.05463C6.95983 9.29746 7.63143 9.10896 8.09863 8.73196Z"
                                                fill="#2D2D2D"/>
                                            <path
                                                d="M13.8802 3.58449C13.5444 3.27999 13.3838 2.90299 13.3838 2.45349C13.3838 2.00399 13.5444 1.62699 13.8802 1.32249C14.216 1.01799 14.6394 0.872986 15.165 0.872986C15.6906 0.872986 16.1286 1.01799 16.4498 1.30799C16.7856 1.59799 16.9462 1.96049 16.9462 2.40999C16.9462 2.88849 16.7856 3.27999 16.4498 3.58449C16.114 3.88899 15.6906 4.04849 15.165 4.04849C14.6394 4.04849 14.216 3.88899 13.8802 3.58449ZM13.7342 5.41149H16.6104V15.2425H13.7342V5.41149Z"
                                                fill="#2D2D2D"/>
                                            <path
                                                d="M21.2094 14.735C20.3626 14.3 19.7202 13.7055 19.2384 12.937C18.7712 12.1685 18.523 11.2985 18.523 10.327C18.523 9.35548 18.7566 8.48548 19.2384 7.71698C19.7056 6.94848 20.3626 6.35398 21.2094 5.91898C22.0562 5.48398 22.9906 5.26648 24.0564 5.26648C25.093 5.26648 26.0128 5.48398 26.7866 5.91898C27.5604 6.35398 28.1298 6.96298 28.4948 7.77498L26.2756 8.96398C25.7646 8.06498 25.02 7.61548 24.0564 7.61548C23.3118 7.61548 22.684 7.86198 22.2022 8.34048C21.7058 8.83348 21.4722 9.48598 21.4722 10.327C21.4722 11.168 21.7204 11.835 22.2022 12.3135C22.6986 12.8065 23.3118 13.0385 24.0564 13.0385C25.0346 13.0385 25.7792 12.589 26.2756 11.69L28.4948 12.8935C28.1444 13.6765 27.575 14.2855 26.7866 14.7205C26.0128 15.1555 25.093 15.373 24.0564 15.373C22.9906 15.3875 22.0562 15.17 21.2094 14.735Z"
                                                fill="#2D2D2D"/>
                                            <path
                                                d="M40.1164 5.4115V15.2425H37.4008V14.068C37.0212 14.4885 36.5686 14.822 36.043 15.0395C35.5174 15.2715 34.948 15.373 34.3348 15.373C33.0354 15.373 31.9988 14.996 31.2396 14.2565C30.4804 13.517 30.1008 12.415 30.1008 10.9505V5.4115H32.977V10.5445C32.977 12.125 33.6486 12.9225 34.9772 12.9225C35.6634 12.9225 36.2182 12.705 36.627 12.2555C37.0504 11.806 37.2548 11.1535 37.2548 10.269V5.4115H40.1164Z"
                                                fill="#2D2D2D"/>
                                            <path
                                                d="M51.1394 5.88998C51.8986 6.31048 52.4826 6.90498 52.9206 7.65898C53.344 8.42748 53.563 9.31198 53.563 10.3125C53.563 11.3275 53.344 12.212 52.9206 12.966C52.4972 13.7345 51.8986 14.3145 51.1394 14.735C50.3802 15.1555 49.548 15.3585 48.6282 15.3585C47.358 15.3585 46.3652 14.967 45.6498 14.1695V18.7515H42.7736V5.41148H45.5184V6.54248C46.2338 5.68698 47.2704 5.26648 48.6282 5.26648C49.548 5.26648 50.3802 5.46948 51.1394 5.88998ZM49.9422 12.3135C50.4094 11.8205 50.6576 11.1535 50.6576 10.327C50.6576 9.50048 50.424 8.83348 49.9422 8.34048C49.475 7.84748 48.8618 7.60098 48.1318 7.60098C47.4018 7.60098 46.7886 7.84748 46.3214 8.34048C45.8542 8.83348 45.606 9.50048 45.606 10.327C45.606 11.1535 45.8396 11.8205 46.3214 12.3135C46.7886 12.8065 47.4018 13.053 48.1318 13.053C48.8618 13.053 49.475 12.8065 49.9422 12.3135Z"
                                                fill="#2D2D2D"/>
                                            <path
                                                d="M72.6014 15.2425L72.5868 7.57196L68.7908 13.894H67.4476L63.6808 7.73146V15.2425H60.8776V2.45346H63.345L68.163 10.3995L72.908 2.45346H75.3608L75.4046 15.2425H72.6014Z"
                                                fill="#2D2D2D"/>
                                            <path
                                                d="M87.8876 11.1245H80.3978C80.5292 11.7335 80.8504 12.212 81.3614 12.5745C81.8578 12.9225 82.4856 13.111 83.2448 13.111C83.7558 13.111 84.223 13.0385 84.6172 12.879C85.0114 12.734 85.391 12.4875 85.7268 12.154L87.2598 13.7925C86.3254 14.851 84.9676 15.3875 83.1718 15.3875C82.0622 15.3875 81.0694 15.17 80.208 14.735C79.3466 14.3 78.6896 13.7055 78.2224 12.937C77.7552 12.1685 77.5216 11.2985 77.5216 10.327C77.5216 9.36999 77.7552 8.49998 78.2078 7.71698C78.675 6.94848 79.3028 6.33948 80.1058 5.90448C80.9088 5.46948 81.814 5.25198 82.8068 5.25198C83.7704 5.25198 84.6464 5.45498 85.4348 5.87548C86.2232 6.29598 86.8364 6.89048 87.289 7.65898C87.7416 8.42748 87.9606 9.32649 87.9606 10.356C87.946 10.3995 87.9168 10.6605 87.8876 11.1245ZM81.1716 7.96349C80.7336 8.32599 80.4562 8.83348 80.3686 9.45699H85.245C85.1428 8.83348 84.88 8.34049 84.442 7.96349C84.004 7.58649 83.4638 7.41248 82.8214 7.41248C82.1644 7.42698 81.6096 7.60099 81.1716 7.96349Z"
                                                fill="#2D2D2D"/>
                                            <path
                                                d="M99.9326 1.685V15.2425H97.1878V14.1115C96.4724 14.967 95.4504 15.3875 94.0926 15.3875C93.1582 15.3875 92.3114 15.1845 91.5668 14.764C90.8222 14.3435 90.2236 13.7635 89.7856 12.995C89.3622 12.2265 89.1432 11.342 89.1432 10.327C89.1432 9.312 89.3622 8.4275 89.7856 7.659C90.209 6.8905 90.8076 6.296 91.5668 5.89C92.326 5.4695 93.1582 5.2665 94.0926 5.2665C95.3628 5.2665 96.341 5.658 97.0564 6.4555V1.685H99.9326ZM96.3994 12.3135C96.8812 11.8205 97.1148 11.1535 97.1148 10.327C97.1148 9.5005 96.8812 8.8335 96.3994 8.3405C95.9176 7.8475 95.319 7.601 94.6036 7.601C93.8736 7.601 93.2604 7.8475 92.7786 8.3405C92.2968 8.8335 92.0632 9.5005 92.0632 10.327C92.0632 11.1535 92.2968 11.8205 92.7786 12.3135C93.2604 12.8065 93.859 13.053 94.6036 13.053C95.319 13.053 95.9176 12.8065 96.3994 12.3135Z"
                                                fill="#2D2D2D"/>
                                            <path
                                                d="M102.736 3.58449C102.4 3.27999 102.239 2.90299 102.239 2.45349C102.239 2.00399 102.4 1.62699 102.736 1.32249C103.072 1.01799 103.495 0.872986 104.021 0.872986C104.546 0.872986 104.984 1.01799 105.305 1.30799C105.641 1.59799 105.802 1.96049 105.802 2.40999C105.802 2.88849 105.641 3.27999 105.305 3.58449C104.97 3.88899 104.546 4.04849 104.021 4.04849C103.495 4.04849 103.057 3.88899 102.736 3.58449ZM102.59 5.41149H105.466V15.2425H102.59V5.41149Z"
                                                fill="#2D2D2D"/>
                                            <path
                                                d="M115.642 6.35398C116.46 7.07898 116.869 8.16648 116.869 9.63098V15.2425H114.182V14.01C113.642 14.9235 112.635 15.373 111.16 15.373C110.401 15.373 109.744 15.2425 109.189 14.996C108.634 14.735 108.211 14.387 107.904 13.9375C107.598 13.488 107.466 12.9805 107.466 12.4005C107.466 11.487 107.817 10.762 108.503 10.24C109.189 9.71798 110.269 9.45698 111.715 9.45698H113.992C113.992 8.83348 113.803 8.35498 113.423 8.02148C113.043 7.68798 112.474 7.51398 111.715 7.51398C111.189 7.51398 110.664 7.60098 110.167 7.76048C109.656 7.91998 109.233 8.15198 108.868 8.42748L107.831 6.44098C108.371 6.06398 109.014 5.77398 109.773 5.57098C110.532 5.36798 111.306 5.26648 112.094 5.26648C113.642 5.26648 114.825 5.62898 115.642 6.35398ZM113.204 13.1255C113.584 12.8935 113.861 12.5745 114.007 12.125V11.1245H112.036C110.853 11.1245 110.269 11.5015 110.269 12.27C110.269 12.6325 110.415 12.9225 110.707 13.14C110.999 13.3575 111.394 13.459 111.89 13.459C112.386 13.4735 112.81 13.3575 113.204 13.1255Z"
                                                fill="#2D2D2D"/>
                                        </svg>

                                    </div>
                                    {
                                        this.state.showGallery &&
                                        <div className="header__buttons">
                                            <a onClick={this.showFilters} className="square__button filter">
                                                             <span className="icon_svg grey">
                                                            <svg width="22" height="15" viewBox="0 0 22 15" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M20.5335 1H1.4668" stroke="#A0A9BC"
                                                                      stroke-width="1.5" stroke-linecap="round"/>
                                                                <path d="M17.9335 5.33334H4.0668" stroke="#A0A9BC"
                                                                      stroke-width="1.5" stroke-linecap="round"/>
                                                                <path d="M16.2001 9.66666H6.66679" stroke="#A0A9BC"
                                                                      stroke-width="1.5" stroke-linecap="round"/>
                                                                <path d="M13.6001 14H8.40013" stroke="#A0A9BC"
                                                                      stroke-width="1.5" stroke-linecap="round"/>
                                                            </svg>
                                                                 </span>
                                                Filters</a>
                                            <a onClick={this.showSearchModal} className="square__button search">
                                                            <span className="icon_svg grey">
                                                               <svg width="20" height="19" viewBox="0 0 20 19"
                                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="8" cy="8" r="7.25" stroke="white"
                                                                        stroke-width="1.5"/>
                                                                <path d="M14 13L19 18" stroke="white" stroke-width="1.5"
                                                                      stroke-linecap="round"/>
                                                            </svg>
                                                            </span>
                                                Search</a>
                                        </div>
                                    }
                                </div>
                                : ''
                        }
                    </div>
                </header>
                <div className={"search_block " + (this.state.showSearch ? "show" : "")}>
                    <svg onClick={this.showSearchModal} className="close__search" width="9" height="14"
                         viewBox="0 0 9 14" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 1L2 7L8 13" stroke="#6C6C6C" stroke-width="1.5"/>
                    </svg>
                    <input ref="sku"
                           value={this.state.filters.sku ? this.state.filters.sku.value[0] : ""}
                           onChange={this.handleSkuChange}
                           onKeyPress={this.handleKeyPress}
                           type="text"
                           className="search__sku"
                    />
                    {this.state.filters.sku && <div className="clear__input_mobile" aria-hidden="true" id="clear-sku-field"
                                                    onClick={this.clearSearch}></div>}
                </div>
                <div className="content">
                    <main className="dashboard">
                        <div className="dashboard__content">
                            {this.state.showGallery ? <Gallery/> : <UnassignedItems/>}
                        </div>
                    </main>
                    <ContactInformationModal sharedItem={false}/>
                    <ItemModal/>
                    <ShareGalleryModal/>
                    <NotificationContainer/>
                    <ConfirmRemoveModal/>
                    <UnassignedModal/>
                    <PasswordModal/>
                    <FiltersModal/>
                    <span id="url"></span>
                    {
                        this.state.showButton ?
                            <div className="contact__button_mobile"
                                 onClick={() => {
                                     this.props.show('ContactInformationModal', {
                                         keyword: this.props.params.gallery,
                                         guid: this.state.gallery_uid
                                     })
                                 }}
                                 style={
                                     {
                                         background: this.state.buttonColor,
                                         color: this.state.buttonTextColor
                                     }
                                 }
                            >
                                <img src={contactUs}/>

                                Contact us
                            </div>
                            :
                            <div className="buttons col-md-2"></div>
                    }
                </div>
                <div className={"custom__backdrop " + (this.state.showSearch ? "show" : "")}
                     onClick={this.showSearchModal}></div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {userData} = state
    return {
        userData: userData,
    }
}
export default connect(mapStateToProps, {show, hide})(GalleryWrapper);