import {REMOVE_FROM_QUEUE, ADD_TO_QUEUE, STOP_QUEUE_CHECK, CLEAR_QUEUE, UPDATE_QUEUE} from "./actionTypes";

export function addToQueue(item, sku_id, status) {
    return {
        type: ADD_TO_QUEUE,
        payload: {item: item, stopChecking: false, sku_id: sku_id, status: status}
    };
}

export function removeFromQueue(id) {
    return {
        type: REMOVE_FROM_QUEUE,
        payload: {id: id}
    };
}

export function stopQueueCheck() {
    return {
        type: STOP_QUEUE_CHECK,
    }
}

export function clearQueue(sku_id) {
    return {
        payload: {sku_id: sku_id},
        type: CLEAR_QUEUE,
    }
}

export function updateQueue(itemId, status) {
    return {
        type: UPDATE_QUEUE,
        payload: {itemId: itemId,status: status}
    };
}

